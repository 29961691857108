@use '../abstract/mixins' as m;
@use '../abstract/variables' as v;
@use '../base/typography' as t;


.host{

    .getstarted{

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 3rem;
        padding: 4.9rem 0 10rem 0;

        @include m.tablet{
            grid-template-columns:  1fr;
        }

        &__rooms{
            position: relative;
            width: 100%;
            height: 60rem;
            width: 100%;
            border-radius: 1.2rem; 
            overflow: hidden;

            @include m.tablet{
                height: 35rem;
            }

            &--image{
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 0;
            }

            &--overlay{
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
                border-radius: 12px;
                width: 100%;
                height: 60rem;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
            }

            

            &--content{
                display: flex;
                flex-direction: row;
                position: absolute;
                top: 4rem;
                left: 3.2rem;
                z-index: 3;

                .imagebox{
                    width: 5rem;
                    height: 5rem;
                    border-radius: 100%;
                    border: 1px solid v.$white-color;
                    margin-right: 1.5rem;
                }

                .details{
                    display: flex;
                    flex-direction: column;
                    color: v.$white-color;
                    justify-content: center;

                    .heading{
                        @extend .body-text-3-semibold;
                    }

                    .subheading{
                        @extend .body-text-4-regular
                    }
                }
            }
        }

        &__joinbox{
            
            padding: 0rem 6.3rem;

            @include m.tablet{
                padding: 3rem 4.3rem;
            }

            @include m.phoneS{
                padding: 3rem 1rem;
            }

            @include m.flexboxz(column, center, flex-start);


            .heading{
                @extend .heading-1-semibold;
                color: v.$grey-color-400;
                line-height: 3.92rem;
                .green{
                    color: v.$secondary-color-400;
                }
            }
            .subheading{
                @extend .heading-4-semibold;
                color: v.$grey-color-400;
                padding: 3.8rem 0 2.8rem 0;
                margin: 0;
            }

            .listcon{
                list-style: none;
                padding: 0;
                display: flex;
                flex-direction: column;

                li{
                    margin-bottom: 3.2rem;
                    @extend .heading-4-regular;
                    color: v.$grey-color-400;
                    display: flex;

                    .icon{
                        color: v.$secondary-color-300;
                        margin-right: 2.4rem;
                    }
                }
            }
        }
    }

    .insurance{
        
        width: 100%;
        min-height: 45.7rem;
        background-color: v.$secondary-color-100;
        border-radius: 1.2rem;
        position: relative;
        margin-top: 6.9rem;
        margin-bottom: 15.5rem;
        @include m.flexboxz(row, space-between, flex-start);
        gap: 3rem;

        &__content{
            width: 60%;
            height: 45.7rem;
            padding-left: 6.4rem;
            padding-right: 6.4rem;
            @include m.flexboxz(column, center, flex-start);

            @include m.tablet{
                flex-direction: column;
                width: 100%;
            }

            @include m.phoneS{
                padding-left: 3rem;
                padding-right: 3rem;
            }

            .heading{
                @extend .display-1-semibold;
                color: v.$grey-color-400;
            }

            .subheading{
                padding: 1.3rem 0 4.2rem 0;
                @extend .heading-3-regular;
                position: relative;

                .badge{
                    // content: 'coming soon';
                    padding: .5rem 1rem;
                    position: absolute;
                    top: 1.5rem;
                    background-color: v.$success-color-50;
                    color: v.$secondary-color-400;
                    @extend .body-text-4-semibold;
                    margin-left: 1.6rem;
                    border-radius: .6rem;

                    @include m.phone{
                        top: -17rem;
                        left: 0;
                        margin: 0;
                    }
                }
            }
        }

        &__imagebox{
            width: 30%;
            height: inherit;
            position: relative;

            @include m.tablet{
                display: none;
            }

            .image{
                position: absolute;
                top: -6.9rem;
                right: 4rem;
            }
            
        }

    }

    .faqbox{
        width: 100%;
        max-width: 70rem;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;

        .heading{
            margin: 0;
            padding-bottom: 6.5rem;
            @extend .display-2-small-semibold;
            color: v.$grey-color-400;
            
            @include m.tablet{
                text-align: center;
            }
        }
    }

    .joinus{

        width: 100%;
        max-width: 85%;
        min-height: 38rem;
        background-color: v.$primary-color-50;
        border-radius: 1.2rem;
        position: relative;
        margin: 12.2rem auto 0 auto;
        @include m.flexboxz(row, space-between, flex-start);
        gap: 3rem;
        
        @include m.tablet{
            flex-direction: column-reverse;
            max-width: 100%;
        }

        &__content{
            width: 60%;
            height: 38rem;
            padding-left: 6rem;
            padding-right: 6rem;
            @include m.flexboxz(column, center, flex-start);

            @include m.tablet{
                width: 100%;
            }

            @include m.phoneS{
                padding-left: 3rem;
                padding-right: 3rem;
            }

            .heading{
                @extend .display-1-small-semibold;
                color: v.$grey-color-400;
                margin: 0;
            }

            .subheading{
                padding: 1.6rem 0 4.2rem 0;
                margin: 0;
                @extend .heading-2-regular;
                position: relative;
            }
        }

        &__imagebox{
            width: 40%;
            max-width: 45.4rem;
            height: 30.6rem;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            @include m.tablet{
                display: none;
            }

            .image{
                position: absolute;
                top: 4.2rem;
                right: -7rem;
                height: 100%;

                @include m.tablet{
                    flex-direction: column-reverse;
                    top: 3.2rem;
                    right: 0rem;
                }
            }
            
        }
    }

    .large{
        padding: 1.5rem 7.5rem;
    }

}