@use '../abstract/mixins' as m;
@use '../abstract/variables' as v;
@use '../base/typography' as t;


.merchant__dashboard {

    .overview {
        @include m.flexboxz(row, space-between, flex-start);
        gap: 2rem;
        margin-bottom: 4rem;

        @include m.phone{
            flex-direction: column;
        }

        &__box {
            background-color: v.$white-color;
            border-radius: .6rem;
            flex-basis: 23%;
            min-height: 11.2rem;
            padding: 2rem;
            display: flex;
            align-items: center;
            gap: 1.6rem;

            @media screen and (max-width: 1100px){
                flex-direction: column;
                align-items: flex-start;
            }

            @include m.phone{
                flex-direction: row;
                align-items: flex-start;
                margin-bottom: 0rem;
                flex-basis: 100%;
                width: 100%;
            }

            .iconbox {
                width: 5.5rem;
                height: 5.5rem;

                img{ width: 100%; height: 100%;}

                @media screen and (max-width: 1100px){
                    width: 4.5rem;
                    height: 4.5rem;
                }
            }
        }

        &__details {

            .value {
                @extend .heading-3-bold;
                color: v.$grey-color-400;
            }

            .text {
                @extend .body-text-4-semibold;
                color: v.$grey-color-300;
            }
        }

    }

    .zcard {
        background-color: v.$white-color;
        border-radius: .6rem;
        min-height: 2rem;
        height: auto;
        width: 100%;
    }

    .analytics {
        @include m.flexboxz(row, flex-start, flex-start);
        width: 100%;
        min-height: 37.8rem;
        height: auto;
        overflow: auto;
        gap: 3rem;

        @media screen and (max-width: 1100px){
            flex-direction: column;
        }

        
        &:not(:last-child){
            margin-bottom: 4rem;
        }

        &__graph {
            width: 66%;
            padding: 2.5rem;
            height: auto;
            min-height: 42.1rem;

            @media screen and (max-width: 1100px){
                width: 100%;
            }

            .header {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                gap: 2rem;
                height: 3rem;

                .heading {
                    @extend .body-text-2-semibold;
                    color: v.$grey-color-300;
                }
                .filters{
                    flex-basis: 14rem;
                }
            }

            .graphbox {
                min-height: 35.8rem;
                height: 35.8rem;
            }

        }

        &__figures {
            width: calc(34% - 3rem);
            padding: 2.1rem 1.8rem;
            display: flex;
            flex-direction: column;
            gap: 1.7rem;
            min-height: 48rem;
            height: auto;

            @media screen and (max-width: 1100px){
                width: 100%;
                flex-direction: row;
            }

            @include m.phone{
                width: 100%;
                flex-direction: column;
            }

            .figurebox {
                padding: 2.8rem 2.2rem;
                border-radius: .6rem;
                min-height: 21rem;
                color: v.$grey-color-300;
                position: relative;

                @media screen and (max-width: 1100px){
                    width: 50%;
                }

                @include m.phone{
                    width: 100%;
                }

                .content {
                    display: flex;
                    flex-direction: column;
                    gap: 6rem;

                    .title {
                        @extend .heading-4-semibold;
                    }

                    .count{
                        @extend .heading-1-bold;
                    }
                }

                .icon {
                    min-width: 4rem;
                    position: absolute;
                    top: 6.5rem;
                    right: 1.5rem;

                    img{ width: 100%; height: 100%;}
                }

                &.blue {
                    background-color: v.$primary-color-50;
                }

                &.green {
                    background-color: v.$secondary-color-50;
                }
            }
        }
    }
}