@use '../abstract/mixins' as m;
@use '../abstract/variables' as v;
@use '../base/typography' as t;

.header {
    width: 100%;
    min-height: 7.2rem;
    height: auto;

    &-container {

        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        @extend .header;
        max-width: v.$container-width;
        margin: 0 auto;

        @include m.tablet{
            position: absolute;
            z-index: 100;
            background-color: v.$white-color;
        }

    }

    .navigation__left {

        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 7.2rem;
        gap: 2.2rem;

        @include m.tablet {
            gap: 0rem;
            flex: 1;
            // position: absolute;
            // z-index: 100;
            background-color: v.$white-color;
        }

        &.open {
            display: flex;

            @include m.tablet {
                padding: 10rem 0 6.5rem 0;
            }
        }

        .brandname {
            // height: 5rem;
            margin-top: -10px;
            height: 3.4rem;

            @include m.tablet {
                position: absolute;
                left: 1.6rem;
                top: 2rem;
            }
        }

        .nav {
            display: flex;
            gap: 1rem;

            @media screen and (min-width: 992px) and (max-width: 1023px){
                gap: 1.6rem;
                font-size: 1.4rem;
            }

            .create-account{
                display: none;
            }

            @include m.tablet {
                flex-basis: 100%;
                gap: 1.2rem;
                flex-direction: column;
                align-items: center;

                font-size: 2.4rem;
                display: none;
            }

            @include m.phone {
                .create-account{
                    display: flex;
                }
            }

            &.open {
                display: flex;
            }

            &-link {
                text-decoration: none;
                color: v.$grey-color-300;
                position: relative;

                &:hover{
                    color: v.$grey-color-400;
                }

                &.active {
                    color: v.$primary-color-300;
                }

                &--new{
                    font-size: 1rem;
                    background-color: v.$secondary-color-50;
                    color: v.$secondary-color-400;
                    padding: 2px 4px;
                    border-radius: 3px;
                    position: absolute;
                    top: -1.2rem;
                }
            }
        }

    }

    .navigation__right {
        // position: relative;
        display: flex;

        @include m.tablet {
            position: absolute;
            right: 1.6rem;
            top: 2rem;
        }

        .actions {
            display: flex;
            gap: 1.6rem;

            @include m.phone {
                .create-account{
                    display: none;
                }
            }

            @include m.phoneS {
                flex-direction: column;
            }
        }

        .auth__wrapper {
            display: flex;
            align-items: center;
            gap: 1.2rem;

            .welcome {
                color: v.$grey-color-300;
                @extend .body-text-2-regular;
                text-transform: capitalize;

                @include m.phoneS {
                    display: none;
                }
            }

            .avatarbox {
                display: flex;
                align-items: center;
                gap: .7rem;
                cursor: pointer;

                .toggle{
                    display: none;
                }

                @include m.phoneS {
                    .toggle{
                        display: none;
                    }
                }

                .avatar {
                    width: 3.5rem;
                    height: 3.5rem;
                    border-radius: 100%;
                    background-color: v.$white-color;
                    box-shadow: 0.5px 0.5px 2px 1px rgba(196, 196, 196, 0.5);
                    overflow: hidden;

                    @include m.flexboxz(row, center, center);

                    color: v.$grey-color-300;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 100%;
                        object-fit: cover;
                    }

                }


            }
        }


        .menu__dropdown {
            position: absolute;
            top: 6.5rem;
            right: 0;
            z-index: 200;
            background-color: v.$white-color;
            box-shadow: v.$box-shadow-8;
            border-radius: .6rem;
            padding: 1.5rem 0rem;
            min-width: 20rem;

            @include m.tablet {
                top: 5rem;
            }

            @extend .body-text-3-regular;
            color: v.$grey-color-400;

            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                .anchor {
                    padding: .9rem 2rem;
                    display: block;
                    color: v.$grey-color-400;
                    text-decoration: none;
                    transition: all 0.3s ease-in-out;
                    cursor: pointer;

                    .icon {
                        margin-right: 1rem;
                    }

                    &:hover {
                        color: v.$primary-color-300
                    }
                }
            }

            .separator {
                width: 100%;
                height: 1px;
                margin: .7rem 0;
                background-color: v.$grey-color-100;
            }
        }

        .mobile__menu {
            color: v.$grey-color-300;
            display: none;
            align-items: center;
            margin-left: 1.6rem;

            @include m.tablet {
                display: flex;
            }
        }
    }


}

.zcontainer {
    @extend .header;
    max-width: 123rem;
    margin: 0 auto;
}