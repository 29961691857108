@use '../abstract/mixins' as m;
@use '../abstract/variables' as v;
@use '../base/typography' as t;

.terms {

    &__header {
        width: 100%;
        min-height: 30rem;
        background-color: v.$secondary-color-50;
        background-image: url('../../assets/images/img/wavebg.png');

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .heading {
            @extend .display-1-small-semibold;
            color: v.$grey-color-400;
            padding: 1.6rem;
            text-align: center;
        }
    }
}

.notifier {
    position: fixed;
    bottom: 5rem;
    left: 2rem;
    width: 100%;
    max-width: 45rem;
    border-radius: 1.2rem;
    @include m.flexboxz(row, flex-start, flex-start);
    gap: 2rem;
    padding: 2.5rem;
    background-color: v.$primary-color-50;
    color: v.$grey-color-400;
    border: 1px solid rgba(v.$primary-color-300, $alpha: 0.3);

    &__iconbox {
        width: 4.6rem;
        height: 4.6rem;
        min-width: 4.6rem;
        border-radius: 1.69rem;
        background-color: v.$primary-color-300;
        @include m.flexboxz(row, center, center);

        .iconcon {
            background-color: v.$white-color;
            border-radius: 50%;
            color: v.$primary-color-300;
            width: 2.9rem;
            height: 2.9rem;
            min-width: 2.9rem;
            @include m.flexboxz(row, center, center);
        }
    }

    &__textbox {
        @include m.flexboxz(row, center, flex-start);
        gap: 1rem;

        .close {
            width: 2.5rem;
            height: 2.5rem;
            min-width: 2.5rem;
            background-color: v.$white-color;
            border-radius: 50%;
            @include m.flexboxz(row, center, center);
            cursor: pointer;
        }

        .link{
            text-decoration: underline;
            color: v.$primary-color-300;
        }
    }
}