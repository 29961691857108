@use '../abstract/mixins' as m;
@use '../abstract/variables' as v;
@use '../base/typography' as t;


.santa {

    &__con {
        width: 100%;
        height: auto;
        min-height: 60rem;
        background-image: url("../../assets/images/img/santabg.png");
        background-repeat: no-repeat;
        background-size: cover;
        padding: 26rem 3rem;
        display: flex;
        justify-content: center;
    }

    &__box {
        width: 100%;
        max-width: 89.3rem;
        border-radius: 2rem;
        height: auto;
        min-height: 58.6rem;
        box-shadow: 0px 13px 74px 8px rgba(0, 0, 0, 0.05);
        z-index: 10;
        background-color: v.$white-color;
        position: relative;
        padding: 9.4rem 6rem;

        @media screen and (max-width: 500px) {
            padding: 6rem;
        }

        @media screen and (max-width: 390px) {
            padding: 4rem;
        }

        .santa-image {
            position: absolute;
            top: -23rem;
            left: 33%;
            height: 23rem;

            @media screen and (max-width: 745px) {
                left: 20%;
            }

            @media screen and (max-width: 500px) {
                left: 16%;
                top: -18rem;
                left: 20%;
                height: 18rem;
            }

            @media screen and (max-width: 406px) {
                left: 10%;
            }
        }

        .heading {
            font: v.$gilroy;
            font-weight: 500;
            font-size: 2.2rem;
            color: v.$grey-color-400;
            line-height: 3.5rem;

            @media screen and (max-width: 500px) {
                font-size: 1.7rem;
            }
        }

        .subbheading {
            font: v.$gilroy;
            font-weight: 400;
            font-size: 2rem;
            padding: 2.7rem 0 5.3rem 0;
            width: 100%;
            max-width: 58.8rem;
            line-height: 3.2rem;
            color: v.$grey-color-400;

            @media screen and (max-width: 500px) {
                font-size: 1.7rem;
                padding: 2.7rem 0 2.1rem 0;
            }
        }

        .congrats {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            text-align: center;

            .title {
                font-size: 3.6rem;
                font: v.$gilroy;
                font-weight: 500;
                margin-bottom: .7rem;
                color: v.$grey-color-400;
            }

            .subtitle {
                color: v.$grey-color-400;
            }
        }
    }
}