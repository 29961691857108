@use '../abstract/mixins' as m;
@use '../abstract/variables' as v;
@use '../base/typography' as t;


.merchant__profile {

    .header {
        .hey{
            @extend .display-2-bold;
            color: v.$grey-color-400;
        }

        .title-heading {
            @extend .display-2-regular;
            color: v.$grey-color-400;
            padding: 0 0 .6rem 0;
        }

        .subtitle-heading {
            @extend .body-text-1-regular;
            color: v.$grey-color-400;
        }

    }


    .contact-container {
        @include m.flexboxz(row, flex-start, flex-start);
        gap: 2rem;
        padding-top: 3rem;

        @include m.tabletS {
            flex-direction: column;
        }

        .box {
            width: 50%;
            background-color: v.$white-color;
            min-height: 25.7rem;
            height: auto;
            border-radius: 1.6rem;
            padding: 2.4rem 2.8rem;
            box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
            border: 1px solid v.$primary-color-100;
            position: relative;

            @include m.tabletS {
                width: 100%;
                gap: 3rem;
            }

            &__icon {
                color: v.$primary-color-300;
                font-size: 4.3rem;
                stroke-width: 1px;
            }

            &__title {
                color: v.$grey-color-400;
                @extend .heading-3-semibold;
                padding-top: 1.2rem;
            }

            &__subtitle {
                padding: .9rem 0 2.8rem 0;
            }

            .action {
                text-decoration: none;

                &:hover {
                    color: v.$white-color;
                }
            }

            .email-accent{
                position: absolute;
                right: 0;
                bottom: 0;
            }
            .phone-accent{
                position: absolute;
                top: 0;
                right: 1rem;
            }

        }
    }
}