@use '../abstract/variables' as v;

// Dissolve

@keyframes dissolve {
    from {
        color: transparent;
    }
    to {
        color: v.$danger-color-300;
    }
}


@keyframes fadeIn {
    from {
        top: -.5rem;
        opacity: 0;
    }

    to {
        top: 0rem;
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        top: 0rem;
        opacity: 1;
    }

    to {
        top: -.5rem;
        opacity: 0;
    }
}