@use '../abstract/mixins' as m;
@use '../abstract/variables' as v;
@use '../base/typography' as t;

.radio {

    &__con {
        margin-bottom: 3.5rem;
    }

    &__heading {
        @extend .body-text-2-regular;
        color: v.$grey-color-300;
        padding-bottom: 2.7rem;
        margin: 0;
    }

    &__box {
        display: flex;
        gap: 7rem;
    }

    &__input {
        display: none;

        &:checked+.radio-check {
            &::before {
                background-color: v.$primary-color-300;
            }
        }
    }

    &__label {
        display: flex;
        gap: 1.5rem;
        align-items: center;
        cursor: pointer;

        .radio-check {
            width: 1.8rem;
            height: 1.8rem;
            display: inline-block;
            border-radius: 100%;
            border: 1px solid v.$primary-color-300;
            background-color: v.$white-color;

            &.checked {
                background-color: v.$primary-color-300 !important;
                border: .4rem solid v.$white-color;
                outline: .3rem solid v.$primary-color-300 ;
                width: 1.8rem;
                height: 1.8rem;
            }
        }

        .radio-text {
            color: v.$grey-color-400;
        }
    }
}