@use '../abstract/mixins' as m;
@use '../abstract/variables' as v;
@use '../base/typography' as t;


.custom-select{
    // height: 4rem;
    

    &__box{
        position: relative;
        height: auto;
        min-height: 4rem;
        max-width: 41rem;
    }

    &__field{
        cursor: pointer;
        padding: .65rem 3.3rem .65rem 1.8rem;
        border-radius: .6rem;
        background-color: v.$white-color;
        border: 1px solid v.$grey-color-200;
        color: v.$grey-color-300;
        width: 100%;
        height: 4rem;
        max-width: 41.2rem;
        min-width: 20rem;
        transition: all .3s ease;
        position: relative;

        &__dropdown{
            position: absolute;
            top: 1rem;
            right: 1rem;
            color: v.$grey-color-200;
            cursor: pointer;
            transition: transform .3s ease-in-out;

            &.opened{
                transform: rotate(-180deg);
            }
        }
    }

    &__optionbox{
        box-shadow: v.$box-shadow-8;
        border-radius: .6rem;
        width: 100%;
        max-width: 26.3rem;
        max-height: 40rem;
        padding: 2rem .8rem 2rem 1.5rem;
        // transition: all .3s ease;
        margin-top: 1rem;
        position: absolute;
        background-color: v.$white-color;
        // transition: all .3s ease-in-out;
        z-index: 100;

        &-enter{
            opacity: 0;
        }
        
        &-enter-active{
            opacity: 1;
            transition: opacity 300ms;
        }

        &-exit{
            opacity: 1;
        }

        &-exit-active{
            opacity: 0;
            transition: opacity 300ms;
        }

        &-roller{

            @include m.flexboxz(column, center, flex-start);
            max-height: 22.7rem;
            height: auto;
            overflow-y: auto;
            scrollbar-color: v.$grey-color-200;
            scrollbar-width: .8rem;
            @extend .body-text-3;
            color: v.$grey-color-400;

            &::-webkit-scrollbar {
                width: .8rem;
              }
              
              &::-webkit-scrollbar-track {
                background-color: v.$grey-color-50;
                border-radius: 10rem;
                width: .8rem;
              }
              
              &::-webkit-scrollbar-thumb {
                background-color: v.$grey-color-200;
                border-radius: 10rem;
              }
        }

        &--value{
            padding: .7rem 1rem;
            cursor: pointer;
            display: block;
            width: 100%;
            transition: all 0.2s ease-in-out;

            &:hover{
                background-color: v.$primary-color-50;
                color: v.$primary-color-300;
            }

            &:active{
                background-color: v.$primary-color-50;
                color: v.$primary-color-300;
            }
        }
    }

    &__search-optioncon{
        margin-right: .7rem;
        margin-bottom: 1.6rem;
        position: relative;

        .icon{
            position: absolute;
            left: 1.2rem;
            top: 1.4rem;
            color: v.$grey-color-200;
        }
    }

    &__search-options{
        width: 100%;
        padding: .2rem 1.8rem .2rem 3.3rem;
        border-radius: .6rem;
        color: v.$grey-color-400;
        background-color: v.$white-color;
        border: 1px solid v.$grey-color-100;
        @extend .body-text-3-regular;
        height: 4rem;

        &:focus{
            border-color: v.$primary-color-200;
            outline: 0;
        }
    }
}