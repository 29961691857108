@use '../abstract/mixins' as m;
@use '../abstract/variables' as v;
@use '../base/typography' as t;

.notfound {

    display: flex;
    flex-direction: row;
    gap: 2rem;
    padding: 12rem 0 6rem 0;

    @include m.tabletS {
        flex-direction: column;
        gap: 5rem;
        padding: 8rem 0 6rem 0;
    }



    &__textbox {
        width: 50%;
        @include m.flexboxz(column, center, flex-start);

        @include m.tabletS {
            width: 100%;
            align-items: center;
            justify-content: center;
            text-align: center;
        }

        .text404 {
            @extend .body-text-2-bold;
            color: v.$primary-color-300;
        }

        .title {
            @extend .display-2-bold;
            color: v.$grey-color-400;
            margin: .6rem 0 2.8rem 0;

            @include m.phoneS{
                font-size: 2.4rem;
            }

        }

        .message {
            color: v.$grey-color-300;
            font-size: 2rem;
            line-height: 3.5rem;
            margin-bottom: 4rem;

            @include m.tabletS{
                text-align: center;
            }
        }

        .actionbox {
            @include m.flexboxz(row, flex-start, flex-start);
            gap: 1.8rem;
        }
    }

    &__imagebox {
        width: 50%;
        @include m.flexboxz(row, center, center);

        @include m.tabletS {
            width: 100%;
        }

        .image {
            width: 100%;
            max-width: 36.4rem;
            height: auto;
            max-height: 33.5rem;
        }
    }

}