@use '../abstract/mixins' as m;
@use '../abstract/variables' as v;
@use '../base/typography' as t;

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #e7e7e7;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #e7e7e7 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


/* small preloader */

.lds-ring-small {
  display: inline-block;
  position: relative;
  width: 25px;
  height: 25px;
}

.lds-ring-small div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 14px;
  height: 14px;
  margin: 8px;
  border: 2px solid #e7e7e7;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #e7e7e7 transparent transparent transparent;
}

.lds-ring-small div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring-small div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring-small div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring-small {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}



.preloadercon {
  width: 100vw;
  height: 100%;
  background-color: rgba(v.$black-color, $alpha: 0.75);
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}