@use '../abstract/mixins' as m;
@use '../abstract/variables' as v;
@use '../base/typography' as t;


.alert {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1.2rem 1.5rem;
    margin: 1.5rem 0;
    font-size: 1.4rem;
    gap: 1rem;
    overflow: hidden;

    .iconbox {
        min-width: 2.3rem;
        height: 2.3rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-success {
        background-color: v.$success-color-50;
        border: 1px solid v.$success-color-300;
        color: v.$success-color-300;

        .iconbox {
            background-color: v.$success-color-300;
            color: v.$success-color-100;
        }
    }

    &-warning {
        background-color: v.$warning-color-50;
        // border: 1px solid v.$warning-color-300;
        color: v.$warning-color-300;

        .iconbox {
            background-color: v.$warning-color-400;
            color: v.$warning-color-50;
        }
    }

    &-info {
        background-color: v.$info-color-100;
        // border: 1px solid v.$info-color-300;
        color: v.$info-color-300;

        .iconbox {
            background-color: v.$info-color-200;
            color: v.$white-color;
        }
    }

    &-danger {
        background-color: v.$danger-color-50;
        color: v.$danger-color-300;

        .iconbox {
            background-color: v.$danger-color-300;
            color: v.$white-color;
        }
    }


    // Second version of alerts


}

.zalert {
    width: 100%;
    padding: 2rem 3rem;
    margin: 1.5rem 0;
    font-size: 1.4rem;
    overflow: hidden;
    border-radius: .6rem;
    line-height: 2.4rem;
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-wrap: wrap;

    &-message{
        display: flex;
        flex-wrap: wrap;
    }


    &-info {
        background-color: v.$info-color-50;
        color: v.$grey-color-300 !important;
        border-left: .4rem solid v.$info-color-400;

        .color {
            color: v.$info-color-400;
        }
    }
}