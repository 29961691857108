@use '../abstract/mixins' as m;
@use '../abstract/variables' as v;
@use '../base/typography' as t;


.agents {
    &__navigation {
        width: 100%;
        height: auto;
        max-height: 70px;

        padding: 3rem 1rem 0rem 1rem;

        .logobox {
            width: 100%;
            height: 4rem;
            @include m.flexboxz(row, center, center);

            .logo {
                height: 4rem;
            }
        }
    }

    &__banner {
        width: 100%;
        height: auto;
        min-height: 18.4rem;
        background: v.$primary-color-100;
        background-image: url('../../assets/images/img/abuja-lagos-bridge.png');
        background-size: cover;
        border-radius: 1.2rem;
        margin-top: 3rem;

        @include m.flexboxz(row, center, center);

        .titlebox {
            padding: 2rem 3.3rem;
            border-radius: .6rem;
            background-color: v.$white-color;
            color: v.$primary-color-300;
            @extend .heading-2-semibold;
        }

    }

    &__formbox {
        width: 100%;
        max-width: 108.8rem;
        height: auto;
        border: 1px solid v.$grey-ad;
        border-radius: 1.2rem;

        justify-content: center;
        margin: 9rem auto;
        padding: 5rem 15.5rem;

        @include m.tablet{
            padding: 5rem 8rem;
        }

        @include m.phoneS{
            padding: 5rem 4rem;
            margin: 5rem auto;
        }

        .success-box {
            @include m.flexboxz(column, center, center);
            gap: 2rem;
            padding: 4rem 0;

            .icon {
                width: 10rem;
            }
        }
    }







    &__space {
        margin-bottom: 2.5rem;

        @include m.tabletS {
            margin-bottom: 1.6rem;

            .mobile__space {
                margin-bottom: 4rem;
            }
        }

        .input__box {
            max-width: 41.2rem;
        }

        .infotext {
            @extend .body-text-5-regular;
            color: v.$grey-color-300;
            display: flex;
            align-items: center;
            gap: .6rem;
            margin-top: -3rem;
            padding-bottom: 3rem;
        }

        .heading {
            @extend .body-text-1-semibold;
            color: v.$grey-color-400;
            margin-bottom: .2rem;
        }

        .subheading {
            @extend .body-text-3-regular;
            color: v.$grey-color-300;
        }
    }
}