@use '../abstract/mixins' as m;
@use '../abstract/variables' as v;
@use '../base/typography' as t;


.toaster {
    width: 100%;
    max-width: 30rem;
    display: flex;
    align-items: center;
    padding: 1.5rem 1.5rem;
    font-size: 1.4rem;
    gap: 1.5rem;
    position: fixed;
    background: v.$white-color;
    box-shadow: v.$box-shadow-toast;
    transform: translate(-50%, 50%);
    left: 50%;
    border-radius: .6rem;
    color: v.$grey-color-300;
    z-index: 1200;

    top: 0rem;

    @include m.phoneXS{
        max-width: 25rem;
    }

    .iconbox {
        min-width: 2.7rem;
        height: 2.7rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .cancel {
        position: absolute;
        top: 1.2rem;
        right: 1.5rem;
        cursor: pointer;
        color: v.$grey-color-300;
    }

    .message {
        margin-bottom: 0;
    }

    .title {
        text-transform: capitalize;
    }

    &.show {
        visibility: visible;
        animation: fadeIn 0.5s, fadeOut 0.5s 3.5s;

        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        perspective: 1000;
        -webkit-perspective: 1000;
    }

    &.hide {
        visibility: hidden;
    }

    &-success {

        .title {
            color: v.$success-color-400;
            @extend .heading-4-semibold;
        }

        .iconbox {
            background-color: v.$success-color-100;
            color: v.$success-color-400;
        }
    }

    &-warning {
        .title {
            color: v.$warning-color-400;
            @extend .heading-4-semibold;
        }

        .iconbox {
            background-color: v.$warning-color-100;
            color: v.$warning-color-400;
        }
    }

    &-info {
        .title {
            color: v.$info-color-400;
            @extend .heading-4-semibold;
        }

        .iconbox {
            background-color: v.$info-color-100;
            color: v.$info-color-400;
        }
    }

    &-danger {
        .title {
            color: v.$danger-color-400;
            @extend .heading-4-semibold;
        }

        .iconbox {
            background-color: v.$danger-color-100;
            color: v.$danger-color-400;
        }
    }


}