
@use  './fonts' as f;
@use  '../abstract/mixins' as m;
@use '../abstract/variables' as v;





body{
    font-family: v.$font-family-base;
    font-size: 1.6rem;
}

@mixin display {
    font-family: v.$gilroy!important;
}

@mixin paragraph {
    font-family: v.$gsans!important;
}


// Display font styles

.display{

    // Display 1
    &-1{
        @include display;
        font-size: 4.8rem;


        &-regular{
            @extend .display-1;
            font-weight: 400;
            line-height: 5.8rem
        }

        &-semibold{
            @extend .display-1;
            font-weight: 600;
            line-height: 5.9rem;
        }

        &-bold{
            @extend .display-1;
            font-weight: 700;
            line-height: 5.9rem;
        }
    }


    // Display 1 small
    &-1{
        &-small{
            @include display;
            font-size: 3.6rem;
            
            &-regular{
                @extend .display-1-small;
                font-weight: 400;
                line-height: 4.3rem;
            }
    
            &-semibold{
                @extend .display-1-small;
                font-weight: 600;
                line-height: 4.4rem;
            }
    
            &-bold{
                @extend .display-1-small;
                font-weight: 700;
                line-height: 4.5rem;
            }
        }
    }

    // Display 2
    &-2{
        @include display;
        font-size: 4rem;


        &-regular{
            @extend .display-2;
            font-weight: 400;
            line-height: 4.8rem;
        }

        &-semibold{
            @extend .display-2;
            font-weight: 600;
            line-height: 4.9rem;
        }

        &-bold{
            @extend .display-2;
            font-weight: 700;
            line-height: 5rem;
        }
    }


    // Display 2 small
    &-2{
        &-small{
            @include display;
            font-size: 3.6rem;

            &-regular{
                @extend .display-2-small;
                font-weight: 400;
                line-height: 3.4rem;
            }
    
            &-semibold{
                @extend .display-2-small;
                font-weight: 600;
                line-height: 3.4rem;
            }
    
            &-bold{
                @extend .display-2-small;
                font-weight: 700;
                line-height: 3.5rem;
            }
        }
    }
}


// Heading font styles


.heading{

    // heading 1
    &-1{
        @include display;
        font-size: 3.2rem;

        &-regular{
            @extend .heading-1;
            font-weight: 400;
            line-height: 3.8rem;
        }

        &-semibold{
            @extend .heading-1;
            font-weight: 600;
            line-height: 3.9rem;
        }

        &-bold{
            @extend .heading-1;
            font-weight: 700;
            line-height: 4rem;
        }
    }

    // Heading 2
    &-2{
        @include display;
        font-size: 2.4rem;

        &-regular{
            @extend .heading-2;
            font-weight: 400;
            line-height: 2.9rem;
        }

        &-semibold{
            @extend .heading-2;
            font-weight: 600;
            line-height: 2.9rem;
        }

        &-bold{
            @extend .heading-2;
            font-weight: 700;
            line-height: 3rem;
        }
    }

    // Heading 3
    &-3{
        @include display;
        font-size: 2.08rem;

        &-regular{
            @extend .heading-3;
            font-weight: 400;
            line-height: 2.5rem;
        }

        &-semibold{
            @extend .heading-3;
            font-weight: 600;
            line-height: 2.5rem;
        }

        &-bold{
            @extend .heading-3;
            font-weight: 700;
            line-height: 2.6rem;
        }
    }

    // Heading 4
    &-4{
        @include display;
        font-size: 1.6rem;

        &-regular{
            @extend .heading-4;
            font-weight: 400;
            line-height: 1.9rem;
        }

        &-semibold{
            @extend .heading-4;
            font-weight: 600;
            line-height: 2rem;
        }

        &-bold{
            @extend .heading-4;
            font-weight: 700;
            line-height: 2rem;
        }
    }

    // Heading 5
    &-5{
        @include display;
        font-size: 1.208rem;

        &-regular{
            @extend .heading-5;
            font-weight: 400;
            line-height: 1.5rem;
        }

        &-semibold{
            @extend .heading-5;
            font-weight: 600;
            line-height: 1.6rem;
        }

        &-bold{
            @extend .heading-5;
            font-weight: 700;
            line-height: 1.6rem;
        }
    }

    // Heading 6
    &-6{
        @include display;
        font-size: 1.102rem;

        &-regular{
            @extend .heading-6;
            font-weight: 400;
            line-height: 1.3rem;
        }

        &-semibold{
            @extend .heading-6;
            font-weight: 600;
            line-height: 1.4rem;
        }

        &-bold{
            @extend .heading-6;
            font-weight: 700;
            line-height: 1.4rem;
        }
    }
}



// Body text font styles


.body-text{

    // Body text 1

    &-1{
        @include paragraph;
        font-size: 1.8rem;

        &-regular{
            @extend .body-text-1;
            font-weight: 400;
        }

        &-semibold{
            @extend .body-text-1;
            font-weight: 600;
        }

        &-bold{
            @extend .body-text-1;
            font-weight: 700;
        }
    }

    // Body text 2

    &-2{
        @include paragraph;
        font-size: 1.6rem;

        &-regular{
            @extend .body-text-2;
            font-weight: 400;
        }

        &-semibold{
            @extend .body-text-2;
            font-weight: 600;
        }

        &-bold{
            @extend .body-text-2;
            font-weight: 700;
        }
    }

    // Body text 3

    &-3{
        @include paragraph;
        font-size: 1.4rem;

        &-regular{
            @extend .body-text-3;
            font-weight: 400;
        }

        &-semibold{
            @extend .body-text-3;
            font-weight: 600;
        }

        &-bold{
            @extend .body-text-3;
            font-weight: 700;
        }
    }

    // Body text 4

    &-4{
        @include paragraph;
        font-size: 1.2rem;

        &-regular{
            @extend .body-text-4;
            font-weight: 400;
        }

        &-semibold{
            @extend .body-text-4;
            font-weight: 600;
        }

        &-bold{
            @extend .body-text-4;
            font-weight: 700;
        }
    }

    // Body text 5

    &-5{
        @include paragraph;
        font-size: 1.1rem;

        &-regular{
            @extend .body-text-5;
            font-weight: 400;
        }

        &-semibold{
            @extend .body-text-5;
            font-weight: 600;
        }

        &-bold{
            @extend .body-text-5;
            font-weight: 700;
        }
    }

    // Body text 6

    &-6{
        @include paragraph;
        font-size: 1rem;

        &-regular{
            @extend .body-text-6;
            font-weight: 400;
        }

        &-semibold{
            @extend .body-text-6;
            font-weight: 600;
        }

        &-bold{
            @extend .body-text-6;
            font-weight: 700;
        }
    }
}



// Text styles

.text-primary{

    &-300{
        color: v.$primary-color-300;
        text-decoration: none;
        &:hover{
            text-decoration: none;
        }
    }

    &-400{
        color: v.$primary-color-400;
        text-decoration: none;
        &:hover{
            text-decoration: none;
        }
    }

    
}


.text-secondary{

    &-300{
        color: v.$secondary-color-300;
        text-decoration: none;
        &:hover{
            text-decoration: none;
        }
    }

    &-400{
        color: v.$secondary-color-400;
        text-decoration: none;
        &:hover{
            text-decoration: none;
        }
    }


}

.text-grey{

    &-300{
        color: v.$grey-color-300;
        text-decoration: none;
        &:hover{
            text-decoration: none;
        }
    }

    &-400{
        color: v.$grey-color-400;
        text-decoration: none;
        &:hover{
            text-decoration: none;
        }
    }


}