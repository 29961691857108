
@use '../abstract/mixins' as m;
@use  '../abstract/variables' as v;
@use  '../base/typography' as t;

.tabs{
    width: 100%;
    height: auto;
    margin-bottom: 2rem;

    &__nav{
        display: flex;
        list-style: none;
        border-bottom: 1px solid v.$grey-color-50;
        padding-left: 0;
        margin: 0;
        gap: 1.2rem;

        &-link{
            color: v.$grey-color-300;
            @extend .body-text-2-regular;
            padding: .5rem 1.5rem;
            cursor: pointer;
            margin: 0;
            transition: all 0.3s ease-in-out;
            

            &.active{
                background-color: v.$primary-color-100;
                color: v.$primary-color-400;
                border-radius: .4rem .4rem 0 0;
            }
        }
    }

    &__contentbox{
        padding: 2rem 0rem;
    }

    &__content{
        display: none;
        transition: all 0.3s ease-in-out;

        &.show{
            display: inline;
        }
    }
}