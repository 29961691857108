// Typography

@mixin font-props($family, $fontsize, $fontweight, $fontcolor){
    font-family: $family;
    font-size: $fontsize;
    font-weight: $fontweight;
    color: $fontcolor;
}

@mixin flexboxz($direction, $justify-content, $align-items) {
    display: flex;
    flex-direction: $direction;
    justify-content: $justify-content;
    align-items: $align-items;
}








// MEDIA QUERY MANAGER
/*
0 - 600px:       Phone
600 - 900px      Tablet portrait
900 - 1200px     Tablet landscape
1200 - 1800px    is where our normal styles apply
1800px + :       Big desktop
*/

@mixin phoneXS{
    @media (max-width: 360px){
        @content
    };
}

@mixin phoneM{
    @media (max-width: 460px){
        @content
    };
}


@mixin phoneS{
    @media (max-width: 511px){
        @content
    };
}

@mixin phone{
    @media (max-width: 600px){
        @content
    };
}

@mixin tabletS{
    @media (max-width: 768px){
        @content
    };
}

@mixin tablet{
    @media (max-width: 992px){
        @content
    };
}

@mixin desktop{
    @media (max-width: 1200px){
        @content
    };
}

@mixin desktopXL{
    @media (max-width: 1400px){
        @content
    };
}

@mixin desktopXXL{
    @media (max-width: 1800px){
        @content
    };
}