@use '../abstract/mixins' as m;
@use '../abstract/variables' as v;
@use '../base/typography' as t;


.rented-rooms{


    &__header{
        @include m.flexboxz(row, space-between, center);
        gap: 2rem;
        padding: 5.2rem 0 5.8rem 0;

        @include m.phoneS{
            flex-direction: column;
            align-items: flex-start;
        }

        .heading{
            @extend .heading-1-semibold;
            color: v.$grey-color-400;
            margin: 0;
        }

        .sortby{
            @include m.phoneS{
                align-self: flex-end;
            }
        }
    }

    .extend-stay{

        .box{
            padding: 2rem;
            background-color: v.$grey-color-50;
            border-radius: .6rem;
            margin-bottom: 4.2rem;

            @include m.flexboxz(row, space-between, center);
            gap: 2rem;

            .datebox{
                @include m.flexboxz(column, flex-start, flex-start);
                gap: .7rem;

                .title{
                    color: v.$secondary-color-400;
                    @extend .body-text-3-semibold;
                }

                .date{
                    color: v.$grey-color-400;
                    @extend .body-text-2-semibold;
                }
            }
        }
    }
}