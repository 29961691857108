@use '../abstract/mixins' as m;
@use '../abstract/variables' as v;
@use '../base/typography' as t;


.rented-rooms{


    &__header{
        @include m.flexboxz(row, space-between, center);
        gap: 2rem;
        padding: 5.2rem 0 5.8rem 0;

        .heading{
            @extend .heading-1-semibold;
            color: v.$grey-color-400;
            margin: 0;
        }
    }
} 