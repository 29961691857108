@use '../abstract/mixins' as m;
@use '../abstract/variables' as v;
@use '../base/typography' as t;

.room-list{

    &__controlbox{
        margin: 4.9rem 0 6.5rem 0;
        @include m.flexboxz(row, space-between, center);
        gap: 2.5rem;
        align-items: baseline;

        @include m.phone{
            flex-direction: column;
        }


        .search__control{
            width: 90%;
            display: flex;
            flex-direction: column;
            gap: 2.5rem;

            @include m.phone{
                width: 100%;
            }

            .search_and_filter{
                // width: 90%;
                display: flex;
                flex-shrink: 0;
                gap: 2.5rem;

                @include m.tablet{
                    gap: 2rem;
                }

                @include m.phoneS{
                    flex-direction: column;
                }

                .reset_filter{
                    color: v.$primary-color-300;
                    @extend .body-text-3-regular;
                    margin-left: 1.2rem;
                    cursor: pointer;
                }
                
            }

            .filter__children{

                display: flex;
                gap: 1.5rem;

                @include m.tablet{
                    flex-direction: column;
                }

                &--dropbox{
                    width: 100%;
                    min-width: 18rem;

                    .select__box{
                        margin-bottom: 0!important;

                        .select__field{
                            padding: 0.8rem 1.8rem!important;
                        }

                        .select__field__dropdown{
                            top: 0.8rem;
                        }
                    }
                    &.group{
                        display: flex;
                        gap: 2rem;
                        min-width: 10rem;
                        width: 10rem;
                    }
                }

                .clearbox{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: v.$primary-color-300;

                    &:hover{
                        color: v.$primary-color-400;
                    }

                    .clear{
                        cursor: pointer;
                    }
                }

                &-enter{
                    opacity: 0;
                }
                
                &-enter-active{
                    opacity: 1;
                    transition: opacity 300ms;
                }
        
                &-exit{
                    opacity: 1;
                }
        
                &-exit-active{
                    opacity: 0;
                    transition: opacity 300ms;
                }
            }
        }

        .filter__control{
            width: auto;
            min-width: 15rem;
            align-self: flex-start;

            @include m.phoneXS{
                display: none;
            }
            
        }
    }

    .paginatecon{
        display: flex;
        margin-top: 4rem;
        gap: 2rem;
        justify-content: center;
        align-items: center;
    }
}