@use '../abstract/mixins' as m;
@use '../abstract/variables' as v;
@use '../base/typography' as t;

.select {
    height: 5.6rem;


    &__box {
        position: relative;
        margin-bottom: 3.5rem; //3.5rem
        max-width: 41.2rem;

        .datepicker {
            position: absolute;
            z-index: 100;
        }
    }

    &__field {
        cursor: pointer;
        padding: 1.45rem 3rem;
        border-radius: .6rem;
        background-color: v.$white-color;
        border: 1px solid v.$primary-color-100;
        color: v.$grey-color-400;
        width: 100%;
        max-width: 41.2rem;
        transition: all .3s ease;
        position: relative;


        &:focus {
            border-color: v.$primary-color-300;
            outline: 0;
            opacity: 1;
            visibility: visible;
            transition: all 0.1s ease-in-out;

            &::placeholder {
                opacity: 0;
                visibility: hidden;
            }

            &.error {
                opacity: 1;
                visibility: visible;
            }
        }

        &:focus.error+label {
            opacity: 1;
            visibility: visible;
        }

        &.error {
            border-color: v.$danger-color-300;

            &::placeholder {
                color: v.$danger-color-300;
            }

            &+label {
                opacity: 0;
                visibility: hidden;
            }
        }

        &__dropdown {
            position: absolute;
            top: 1.5rem;
            right: 1rem;
            cursor: pointer;
            transition: transform .3s ease-in-out;

            &.opened {
                transform: rotate(-180deg);
            }
        }

        @supports (not (-ms-ime-align:auto)) {

            &:focus+label,
            &:not(:placeholder-shown)+label {
                @extend .body-text-3-regular;
                color: v.$primary-color-300;
                transform: translate(0rem, -2.3rem);
                position: absolute;
                top: 0;
                left: 0;
            }

            // &:not(:placeholder-shown) + label{
            //     color: v.$primary-color-200;
            // }
        }


    }



    &__label {
        @extend .body-text-3-regular;
        color: transparent;
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        transform: translate(0rem, -1.6rem);
        transition: all 0.1s ease-out;
        cursor: text;

        &.error {
            color: v.$danger-color-400 !important;
        }
    }

    &__optionbox {
        box-shadow: v.$box-shadow-8;
        border-radius: .6rem;
        width: 100%;
        // max-width: 26.3rem;
        max-height: 40rem;
        padding: 2rem .8rem 2rem 1.5rem;
        transition: all .3s ease;
        margin-top: 1rem;
        position: absolute;
        background-color: v.$white-color;
        transition: all .2s ease-in-out;
        z-index: 100;

        &-roller {

            @include m.flexboxz(column, flex-start, flex-start);
            max-height: 24rem;
            // max-height: 22.7rem;
            height: auto;
            overflow-y: auto;
            scrollbar-color: v.$grey-color-200;
            scrollbar-width: .8rem;
            @extend .body-text-3;
            color: v.$grey-color-400;

            &::-webkit-scrollbar {
                width: .8rem;
            }

            &::-webkit-scrollbar-track {
                background-color: v.$grey-color-50;
                border-radius: 10rem;
                width: .8rem;
            }

            &::-webkit-scrollbar-thumb {
                background-color: v.$grey-color-200;
                border-radius: 10rem;
            }

            .check__box {
                &--label {
                    display: flex;
                    gap: 1.3rem;
                    margin-bottom: .8rem;

                    input[type="checkbox"] {
                        width: 1.15em;
                        height: 1.15em;
                        border: 0.13em solid v.$grey-color-200;
                        border-radius: 0.15em;
                    }
                }

                &--input {
                    width: 3rem;
                    height: 3rem;
                }

            }
        }

        &--value {
            padding: .7rem 1rem;
            cursor: pointer;
            display: block;
            width: 100%;
            transition: all 0.2s ease-in-out;

            &:hover {
                background-color: v.$primary-color-50;
                color: v.$primary-color-300;
            }
        }
    }

    &__search-optioncon {
        margin-right: .7rem;
        margin-bottom: 1.6rem;
        position: relative;

        .icon {
            position: absolute;
            left: 1.2rem;
            top: 1.4rem;
            color: v.$grey-color-200;
        }
    }

    &__search-options {
        width: 100%;
        padding: .2rem 1.8rem .2rem 3.3rem;
        border-radius: .6rem;
        color: v.$grey-color-400;
        background-color: v.$white-color;
        border: 1px solid v.$grey-color-100;
        @extend .body-text-3-regular;
        height: 4rem;

        &:focus {
            border-color: v.$primary-color-200;
            outline: 0;
        }
    }

    &__error {
        color: v.$danger-color-300;
        @extend .body-text-3-regular;
        margin-top: 0rem;
        animation: dissolve 0.2s ease-in-out;
    }
}