@use '../abstract/mixins' as m;
@use '../abstract/variables' as v;
@use '../base/typography' as t;


// Margins utility classes
.mar{
    &-t3{
        margin-top: 3rem;
    }
    
    &-t83{
        padding-top: 8.3rem;
    }
}



// Padding utility classes
.pad{
    &-t3{
        padding-top: 3rem;
    }
    
}

// text link

.text-link{
    
    &-primary{
        color: v.$primary-color-300;
        text-decoration: underline;
        cursor: pointer;

        &:hover{
            color: v.$primary-color-400;
        }
    }

    &-danger{
        color: v.$danger-color-300;
        text-decoration: underline;
        cursor: pointer;

        &:hover{
            color: v.$danger-color-400;
        }
    }
}


// Badge

.section__badge{
    color: v.$grey-color-300;
    text-transform: uppercase;
    @extend .body-text-3-semibold;
    border-radius: .6rem;
    padding: .5rem 2rem;
    display: table;
    margin: 4.2rem 0 3rem 0;

    &--success{
        background-color: v.$success-color-50;
    }
}