@use '../abstract/mixins' as m;
@use '../abstract/variables' as v;
@use '../base/typography' as t;


// Custom list navigation

.custom-listnav {
    display: flex;
    justify-content: space-between;

    .navigation {
        @include m.flexboxz(row, center, center);
        gap: 3rem;

        .round {
            width: 5rem;
            height: 5rem;
            border: 1px solid v.$grey-color-200;
            border-radius: 50%;
            background-color: v.$white-color;
            @include m.flexboxz(row, center, center);
            transition: all 0.3s ease-in-out;
            cursor: pointer;

            .icon {
                font-size: 1.6rem;
                color: v.$grey-color-200;
                transition: all 0.3s ease-in-out;
            }

            &:hover {
                border: 1px solid v.$secondary-color-300;
                background-color: v.$secondary-color-300;
            }

            &:hover .icon {
                color: v.$white-color;
            }
        }

        &.znavtop {
            margin-top: 4.5rem;
        }
    }
}


.loadingGif {
    height: 6rem;
}