@use '../abstract/mixins' as m;
@use '../abstract/variables' as v;
@use '../base/typography' as t;

.onboarding {

    display: flex;
    min-height: 100vh;
    height: auto;
    overflow: auto;
    background-color: v.$grey-color-100;
    position: relative;

    &__logo {
        width: 14rem;
        margin: 7rem 0 0 7rem;
        // height: 5rem;
    }

    &__image {
        width: 30%;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: v.$primary-color-200;

        &.authbg-1 {
            background-image: url('../../assets/images/img/authbg-1.png');
        }

        &.authbg-2 {
            background-image: url('../../assets/images/img/authbg-2.png');
        }

        &.authbg-3 {
            background-image: url('../../assets/images/img/authbg-3.png');
        }

        &.authbg-4 {
            background-image: url('../../assets/images/img/authbg-4.png');
        }

        @media screen and (max-width: 892px) {
            display: none;
        }
    }

    &__content {
        width: 70%;
        min-height: 100vh;
        overflow: auto;
        position: absolute;
        top: 0;
        right: 0;
        padding: 10rem 0;
        @include m.flexboxz(column, center, center);

        // Responsiveness
        @media screen and (max-width: 892px) {
            width: 100%;
            padding-left: 2rem;
            padding-right: 2rem;
        }

        &--title {
            text-align: center;
            color: v.$grey-color-300;
        }

        &--subtitle {
            text-align: center;
            color: v.$grey-color-400;
        }

        &--logo {
            margin: 0 0 3rem 0;
            width: 14rem;
            visibility: hidden;
            display: none;

            // Responsiveness
            @media screen and (max-width: 892px) {
                display: block;
                visibility: visible;
            }

        }

        &--contentbox {
            background-color: v.$white-color;
            border-radius: 1.2rem;
            padding: 6rem 5.2rem;
            width: 100%;
            max-width: 50.9rem;
            margin-top: 4.2rem;

            @include m.phoneXS {
                padding: 4rem 3rem;
            }

            .forgotlink {
                @extend .body-text-3;
                color: v.$primary-color-300;
                margin-top: -1rem;
                position: absolute;
                text-decoration: none;
                transition: all 0.2s ease-in-out;

                &:hover {
                    text-decoration: none;
                    color: v.$primary-color-400;
                }
            }

            .action-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                @include m.phoneS {
                    flex-direction: column;
                    gap: 2rem;
                    align-items: flex-start;
                }

                .text {
                    color: v.$grey-color-300;
                    @extend .body-text-3;
                }
            }

            .statusbox {
                @include m.flexboxz(column, center, center);

                &__userinfo {
                    @extend .body-text-3-regular;
                    color: v.$grey-color-400;
                    text-align: center;
                    margin-top: 3.2rem;
                    line-height: 2.7rem;

                    .username {
                        @extend .body-text-3-semibold;
                    }

                    .email {
                        color: v.$primary-color-300;
                    }
                }

                .resend {
                    @extend .body-text-3-regular;
                    color: v.$grey-color-400;
                    margin-top: 2rem;
                    text-align: center;

                    .action {
                        color: v.$primary-color-300;
                        cursor: pointer;
                    }
                }

                .iconbox {
                    width: 12rem;
                    height: 12rem;
                    background-color: v.$secondary-color-100;
                    color: v.$secondary-color-400;
                    border-radius: 50%;
                    @include m.flexboxz(column, center, center);

                    &--error {
                        background-color: v.$danger-color-100;
                        color: v.$danger-color-400;
                    }

                    &--warning {
                        background-color: v.$warning-color-100;
                        color: v.$warning-color-400;
                    }

                    &--info {
                        background-color: v.$info-color-100;
                        color: v.$info-color-400;
                    }
                }

            }

            .terms {
                @extend .body-text-4-regular;
                color: v.$grey-color-300;
            }


            .resend-text {
                color: v.$grey-color-300;
                @extend .body-text-3-regular;

                span {
                    cursor: pointer;
                }
            }

        }

    }

}