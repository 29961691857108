@use '../abstract/mixins' as m;
@use '../abstract/variables' as v;
@use '../base/typography' as t;

.merchant__header {
    width: calc(100% - 25rem);
    height: auto;
    min-height: 7.6rem;
    background-color: v.$white-color;
    padding: 1rem 2rem;
    position: fixed;
    top: 0;
    left: 25rem;
    z-index: 10;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .menu {
        display: none;
        font-size: 2.5rem;

        @include m.tablet {
            display: inline-block;
        }
    }

    @include m.tablet {
        width: 100%;
        left: 0rem;
    }

    .dropdown__box {
        position: relative;
    }

    .auth__wrapper {
        display: flex;
        align-items: center;
        gap: 1.2rem;
        justify-content: flex-end;

        .welcome {
            color: v.$grey-color-300;
            @extend .body-text-2-regular;
            text-transform: capitalize;

            @include m.phoneS {
                display: none;
            }
        }

        .avatarbox {
            display: flex;
            align-items: center;
            gap: .7rem;
            cursor: pointer;

            .toggle {
                display: none;
            }

            @include m.phoneS {
                .toggle {
                    display: none;
                }
            }

            .avatar {
                width: 3.5rem;
                height: 3.5rem;
                border-radius: 100%;
                background-color: v.$white-color;
                box-shadow: 0.5px 0.5px 2px 1px rgba(196, 196, 196, 0.5);
                overflow: hidden;

                @include m.flexboxz(row, center, center);

                color: v.$grey-color-300;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 100%;
                    object-fit: cover;
                }

            }


        }
    }


    .menu__dropdown {
        position: absolute;
        top: 6.5rem;
        right: 0;
        z-index: 2;
        background-color: v.$white-color;
        box-shadow: v.$box-shadow-8;
        border-radius: .6rem;
        padding: 1.5rem 0rem;
        min-width: 20rem;

        @include m.tablet {
            top: 5rem;
        }

        @extend .body-text-3-regular;
        color: v.$grey-color-400;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            .anchor {
                padding: .9rem 2rem;
                display: block;
                color: v.$grey-color-400;
                text-decoration: none;
                transition: all 0.3s ease-in-out;
                cursor: pointer;

                .icon {
                    margin-right: 1rem;
                }

                &:hover {
                    color: v.$primary-color-300
                }
            }
        }

        .separator {
            width: 100%;
            height: 1px;
            margin: .7rem 0;
            background-color: v.$grey-color-100;
        }
    }
}