@use '../abstract/mixins' as m;
@use '../abstract/variables' as v;
@use '../base/typography' as t;

.modal{

    &__overlay{
        width: 100vw;
        height: 100%;
        background-color: rgba(v.$black-color, $alpha: 0.75);
        z-index: 999;
        position: fixed;
        top: 0;
        left: 0;
    } 

    &__wrapper{
        @extend .modal__overlay;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__container{
        width: 100%;
        max-width: 63.5rem;
        min-height: 2rem;
        height: auto;
        background-color: v.$white-color;
        z-index: 1000;
        padding: 5rem 7rem;
        border-radius: 1.2rem;
        position: relative;

        @include m.phone{
            padding: 5rem 4rem;
        }

        &.small{
            max-width: 55rem!important;
            width: 100%;
        }

        &.large{
            max-width: 80rem!important;
            width: 100%;
        }

        .close{
            width: 3.5rem;
            height: 3.5rem;
            background-color: v.$white-color;
            @include m.flexboxz(column, center, center);
            position: absolute;
            top: -5rem;
            right: 0;
            border-radius: 50%;
            cursor: pointer;

            .icon{
                color: v.$grey-color-300;
            }
        }
    }

    &__content{
        width: 100%;
        margin: 0 auto;
    }

    &__header{
        @include m.flexboxz(row, space-between, center);
        gap: 2rem;

        &--title{
            color: v.$grey-color-400;
            @extend .heading-2-semibold;
            margin-bottom: 2.8rem;
        }
    }

    &__body{
        margin: 0rem 0 0 0;
    }

    // MODAL CAROUSEL STYLES
    &__carousel{
        @extend .modal__overlay;
        display: flex;
        justify-content: center;
        align-items: center;

        .wrapper{
            width: 70%;
            height: 75%;
            background-color: transparent;
            display: flex;
            flex-direction: column;
            gap: 2.7rem;
            z-index: 1000;

            .controls{
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 1rem;
                color: v.$grey-color-400;

                .counter{
                    padding: .7rem 1.6rem;
                    background-color: v.$white-color;
                    border-radius: .6rem;
                    @extend .body-text-1-regular;
                }
                .cancel{
                    width: 4rem;
                    height: 4rem;
                    background-color: v.$white-color;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    .icon{
                        color: v.$grey-color-400;
                    }
                }
            }

            .imagebox{
                // background-color: v.$white-color;
                width: 100%;
                height: 100%;
                border-radius: 1.2rem;
                position: relative;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;

                .arrow{
                    width: 5rem;
                    height: 5rem;
                    border-radius: 50%;
                    position: absolute;
                    top: 45%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: v.$secondary-color-300;
                    cursor: pointer;
                    transition: all .3s ease-in-out;

                    &:hover{
                        background-color: v.$secondary-color-400;
                    }

                    &-left{
                        left: 2.5rem;
                    }

                    &-right{
                        right: 2.5rem;
                    }

                    .icon{
                        color: v.$white-color;
                    }

                    &.disabled{
                        pointer-events: none;
                        background-color: v.$secondary-color-200;
                        cursor: not-allowed;
                    }
                }

                .single__image{
                    height: 100%;
                    width: 100%;
                    // object-fit: cover;
                    object-fit: contain;
                }
            }
        }
    }
}