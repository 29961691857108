@use '../abstract/mixins' as m;
@use '../abstract/variables' as v;
@use '../base/typography' as t;

.check__box {

    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    margin-bottom: 5rem;


    &--title {
        color: v.$grey-color-400;
        flex-basis: 100%;
        @extend .heading-4-semibold;
    }

    input{
        height: 1.8rem;
        width: 1.5rem;
        border-radius: 1.5rem;
    }

    &--label {
        display: flex;
        gap: 1.3rem;
        align-items: center;
        cursor: pointer;
        color: v.$grey-color-400;
    }

    &--checker {
        margin-right: 1rem;
    }
}