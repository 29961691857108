
@use '../abstract/variables' as v;
@use '../base/typography' as t;
@use '../abstract/mixins' as m;


.accordion{

    width: 100%;
    height: auto;
    margin-bottom: 2rem;

    &__box{
        display: flex;
        flex-direction: column;
        
    }

    &__heading{
        width: 100%;
        @include m.flexboxz(row, space-between, center);
        height: 4rem;
        padding: 0 1.3rem;
        margin-bottom: 2rem;
        border-bottom: 1px solid v.$grey-color-100;
        cursor: pointer;
        background-color: v.$white-color;

        .text{
            @extend .heading-4-semibold;
            color: v.$grey-color-400;
            transition: all .3s ease-in-out;
            margin: 0;

            &.active{
                color: v.$primary-color-300;
            }
        }

        .icon{
            color: v.$grey-color-200;
            transition: transform .3s ease-in-out;
            margin-left: 2rem;

            &.active{
                color: v.$primary-color-300;
                transform: rotate(-180deg);
            }
        }
    }

    &__content{
        width: 100%;
        padding: 2rem 1.3rem 2rem 1.3rem;
        color: v.$grey-color-300;
        @extend .body-text-2-regular;
        line-height: 2.7rem;
        background-color: v.$white-color;

        transition: all 0.7s ease-in-out;

        max-height: 0;
        opacity: 0;
        visibility: hidden;
        overflow: hidden;

        &.active{
            max-height: unset;
            opacity: 1;
            visibility: visible;
            overflow: auto;
        }
    }
}