@use '../abstract/mixins' as m;
@use '../abstract/variables' as v;
@use '../base/typography' as t;

.reservation-summary{

    .heading{
        @include m.flexboxz(row, none, center);
        gap: 3.7rem;
        margin-top: 5.6rem;


        &__backicon{
            width: 4.5rem;
            height: 4.5rem;
            background-color: v.$grey-color-100;
            border-radius: 50%;

            @include m.flexboxz(row, center, center);

            color: v.$grey-color-300;
            cursor: pointer;
        }

        &__text{
            @extend .heading-1-semibold;
            color: v.$grey-color-400;
        }
    }

    .payment{

        display: flex;
        margin-top: 6.3rem;
        gap: 7.5rem;

        // tablet responsive
        @include m.tablet{
            flex-direction: column;
        }

        .hostel__content{
            background-color: v.$grey-color-50;
            min-height: 40.2rem;
            border-radius: 1.2rem;
            flex-basis: 60%;

            padding: 3.5rem 3.2rem;

            .details__box{
                display: flex;
                flex-direction: row;
                gap: 2.8rem;
                border-bottom: 1px solid #E8E7E6;
                padding-bottom: 3.5rem;

                // responsive phoneS
                @include m.phoneS{
                    flex-direction: column;
                }

                .imageblock{
                    width: 20rem;
                    height: 18rem;
                    border-radius: 1.2rem;
                    background-color: v.$grey-color-100;
                    overflow: hidden;

                    // responsive phoneS
                    @include m.phoneS{
                        width: 100%;
                    }

                    .image{
                        width: 100%;
                        height: 18rem;
                        object-fit: cover;
                    }
                }

                .nameanduser{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    gap: 1.5rem;

                    .name{
                        display: flex;
                        flex-direction: column;
                        gap: 0.4rem;

                        .heading{
                            @extend .heading-3-semibold;
                            color: v.$grey-color-400;
                            margin: 0;
                        }

                        .subheading{
                            @extend .body-text-3-regular;
                            color: v.$grey-color-300;
                            margin: 0;
                        }

                    }

                    .user{
                        display: flex;
                        gap: 1.5rem;

                        .profile__image{
                            width: 5rem;
                            height: 5rem;
                            background-color: v.$secondary-color-100;
                            border-radius: 50%;
                            overflow: hidden;

                            .image{
                                width: 5rem;
                                height: 5rem;
                                object-fit: cover;
                            }
                        }

                        .reviewbox{
                            display: flex;
                            flex-direction: column;
                            gap: 3px;
                            justify-content: center;

                            .hostname{
                                @extend .body-text-3-semibold;
                                color: v.$grey-color-400;
                            }

                            .reviews{
                                @extend .body-text-4-regular;
                                color: v.$grey-color-300;
                            }
                        }
                    }
                }
            }


            .date__details{
                display: flex;
                justify-content: space-between;
                margin-top: 4.7rem;
                gap: 4rem;

                // responsiveness
                @include m.phoneS{
                    flex-direction: column
                }

                .checkinout{
                    .title{
                        @extend .body-text-3-semibold;
                        color: v.$secondary-color-300;
                        text-transform: uppercase;
                        margin-bottom: 1rem;
                    }

                    .datetime{
                        color: v.$grey-color-400;
                        @extend .body-text-1-semibold;
                        margin: 0;
                        display: flex;
                        align-items: center;

                        .time{
                            @extend .body-text-3-semibold;
                            margin-right: 2.2rem;
                        }

                        .edit{
                            @extend .body-text-3;
                            cursor: pointer;
                            display: flex;
                            gap: 1.1rem;
                        }
                    }
                }
            }

        }

        &__summary{
            flex-basis: 40%;
            background-color: v.$white-color;
            border-radius: 1.2rem;
            border: 1px solid v.$grey-color-100;
            box-shadow: v.$bottom-shadow;
            padding: 3.5rem 4.6rem;

            .title{
                @extend .heading-2-semibold;
                margin-bottom: 3.8rem;
                color: v.$grey-color-400;
            }

            .payment-review{
                display: flex;
                justify-content: space-between;
                gap: .5rem;

                @extend .body-text-2-regular;
                color: v.$grey-color-400;
                margin-bottom: 1.2rem;

                &.last{
                    border-top: 1px solid #E8E7EC;
                    @extend .body-text-1-bold;
                    padding-top: 1.8rem;
                    margin-bottom: 0;
                }
            }
            .cta{
                margin-top: 3.8rem;

                .terms{
                    @extend .body-text-4-regular;
                    color: v.$grey-color-400;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    margin-top: 1rem;

                    .link{
                        text-decoration: underline;
                    }
                }
            }

        }
    }


    .authcheck__box{
        padding-top: 5rem;

        .policy, .auth{
            .title{
                @extend .heading-3-semibold;
                color: v.$grey-color-400;
                margin-bottom: 4rem;
            }

            .content{
                @extend .body-text-2-regular;
                color: v.$grey-color-300;
                margin-bottom: 1.6rem;
            }
        }

        .cta{
            display: flex;
            gap: 4.2rem;

            @media screen and (max-width: 400px){
                flex-direction: column;
            }
        }

        .login__box{
            width: 100%;
            max-width: 48.5rem;
            padding: 6rem 5.2rem;
            border-radius: 1.2rem;
            border: 1px solid v.$grey-ad;
            margin-top: 4.8rem;
        }
    }
}
