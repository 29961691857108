@use '../abstract/mixins' as m;
@use '../abstract/variables' as v;
@use '../base/typography' as t;



.progressbar {
    position: relative;
    margin: 1.5rem 0 0 0;

    .label {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        font-size: 1.3rem;
        color: v.$grey-color-400;

        &.white{
            color: v.$white-color!important;
        }
    }

    progress[value] {
        width: 100%;
        height: 1rem;
        -webkit-appearance: none;
        appearance: none;
    }

    progress[value]::-webkit-progress-bar {
        background-color: v.$grey-color-100;
        border-radius: .4rem;
        // box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
    }

    progress[value]::-webkit-progress-value {
        background-color: #074AD7;
        border-radius: .4rem;
    }


}