@use '../abstract/mixins' as m;
@use '../abstract/variables' as v;
@use '../base/typography' as t;

.room-details {


    .room-info {
        margin-top: 3.8rem;
        @include m.flexboxz(row, space-between, center);

        @include m.phone {
            flex-direction: column;
            align-items: flex-start;
            gap: 2rem;
        }

        &__name {
            @include m.flexboxz(column, flex-start, flex-start);

            .name {
                @extend .heading-2-semibold;
                color: v.$grey-color-400;
                margin-bottom: .5rem;
            }

            .location {
                @extend .body-text-3-regular;
                color: v.$grey-color-300;
            }
        }

        &__price {
            display: flex;
            align-items: center;
            gap: 2.1rem;

            @include m.phone {}

            .value {
                @extend .display-2-small-semibold;
                color: v.$grey-color-400;
                margin: 0;

                .day {
                    @extend .heading-4-semibold
                }
            }

            .controls {
                border-left: 1px solid v.$grey-ad;
                display: flex;
                gap: 2.9rem;
                padding-left: 2.1rem;
                position: relative;

                .iconcon {
                    cursor: pointer;
                    // transition: all 0.3s ease-in-out;
                    // background-color: v.$primary-color-50;
                    // border-radius: .6rem;
                    // padding: .5rem .8rem;

                    // &:hover{
                    //     box-shadow: v.$box-shadow-8;
                    // }
                }

                .icon {
                    color: v.$grey-color-300;
                }

                .sharebox{
                    position: absolute;
                    top: 4rem;
                    right: 0rem;
                    display: flex;
                    gap: 2rem;
                    background-color: v.$white-color;
                    border-radius: .6rem;
                    padding: 1rem 1.5rem;
                    box-shadow: v.$box-shadow-toast;
                    z-index: 100;

                    .icon{
                        color: v.$grey-color-400;
                        cursor: pointer;
                        transition: all 0.2s ease-in-out;

                        &:hover{
                            color: v.$secondary-color-300;
                        }
                    }
                }
            }
        }
    }


    .room-images {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        gap: 1.5rem;
        margin-top: 1.9rem;


        @include m.tabletS {
            grid-template-columns: 2fr 1fr;
        }

        @include m.phone {
            grid-template-columns: 2fr 1fr;
        }

        @include m.phoneS {
            grid-template-columns: 2fr;
        }

        .imagebox {
            max-height: 17rem;
            overflow: hidden;
            background-color: v.$grey-color-50;
            cursor: pointer;

            @include m.tabletS {
                .image {
                    max-height: 30rem;
                }
            }

            @include m.phoneS {
                .image {
                    max-height: 30rem;
                }
            }

            &:nth-child(1) {
                grid-row: 1 / 3;
                border-radius: 1.2rem 0 0 1.2rem;
                max-height: 35.5rem;

                .image {
                    height: 35.5rem;
                }

                @include m.phoneS {
                    display: none;
                }
            }

            &:nth-child(2) {
                @include m.tabletS {
                    display: none;
                }
            }

            &:nth-child(3) {
                border-radius: 0 1.2rem 0 0;

                @include m.phoneS {
                    display: none;
                }
            }

            &:nth-child(4) {
                @include m.tabletS {
                    display: none;
                }
            }

            &:nth-child(5) {
                border-radius: 0 0 1.2rem 0;
                position: relative;

                @include m.phoneS {
                    max-height: 30rem;

                    .image {
                        max-height: 30rem;
                    }
                }
            }

            .image {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .image__counter {
                @extend .body-text-3-semibold;
                padding: 1rem 1.4rem;
                background-color: v.$white-color;
                border-radius: .6rem;
                border: 1px solid v.$grey-color-200;
                position: absolute;
                right: 2rem;
                bottom: 2rem;
                display: flex;
                align-items: center;
                gap: 1rem;
                color: v.$grey-color-300;
                transition: all 0.3s ease-in-out;
                cursor: pointer;

                &:hover {
                    background-color: rgba(v.$white-color, $alpha: .9);
                }

                .icon {
                    color: v.$grey-color-300;
                }
            }
        }
    }


    .room-details {

        display: grid;
        grid-template-columns: 2fr 1fr;
        margin-top: 3.2rem;
        gap: 3rem;

        @include m.tablet {
            grid-template-columns: 1fr;
        }

        &__main {

            // Host
            .host {

                @include m.flexboxz(row, space-between, center);
                gap: 2rem;

                @include m.phone {
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                }

                &__details {
                    @include m.flexboxz(row, flex-start, center);
                    gap: 1.5rem;

                    .picture {
                        width: 50px;
                        min-width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        // border: 1px solid #c4c4c4;
                        overflow: hidden;
                        // background-color: v.$grey-color-200;

                        .image {
                            width: 50px;
                            min-width: 50px;
                            height: 50px;
                            object-fit: cover;

                        }
                    }

                    .text {
                        .username {
                            @extend .body-text-3-semibold;
                            color: v.$grey-color-400;
                        }

                        .membership {
                            @extend .body-text-4-regular;
                            color: v.$grey-color-300;
                        }
                    }
                }

                &__reviews {
                    display: flex;
                    gap: 1.5rem;
                    color: v.$primary-color-300;

                    @include m.phone {
                        align-self: flex-end;
                    }

                    .user-reviews {
                        @extend .body-text-3-regular;
                    }

                    .icon {
                        color: v.$primary-color-300;
                    }
                }

                &__room-status {
                    background-color: v.$grey-color-50;
                    color: v.$grey-color-400;
                    @extend .body-text-5-regular;
                    border-radius: .6rem;
                    padding: .5rem 1rem;

                    &.available {
                        background-color: v.$secondary-color-50;
                        color: v.$secondary-color-400;
                    }

                    &.inuse {
                        background-color: v.$primary-color-50;
                        color: v.$primary-color-300;
                    }
                }
            }

            // Amenities
            .amenities {

                box-shadow: v.$bottom-shadow;

                .amenitiesbox {
                    @include m.flexboxz(row, flex-start, center);
                    gap: 2rem 7rem;
                    flex-wrap: wrap;
                    padding-bottom: 4.2rem;

                    @include m.phone {
                        gap: 2rem 4rem;
                    }

                    .item {
                        display: flex;
                        align-items: center;
                        gap: 1rem;
                        color: v.$grey-color-400;

                        .icon {
                            color: v.$grey-color-400;
                        }
                    }

                }


                .seemore {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;

                    .btnlink {
                        background-color: transparent;
                        outline: none;
                        color: v.$grey-color-200;
                        margin: 3.2rem 0 4.2rem 0;
                        border: none;
                        text-decoration: underline;
                    }
                }
            }

            // Rules 
            .rules {

                box-shadow: v.$bottom-shadow;
                padding-bottom: 4.2rem;

                .therule {
                    list-style: none;
                    margin: 0;
                    padding: 0;

                    .item {
                        display: flex;
                        gap: 1.5rem;
                        color: v.$grey-color-400;
                        margin-bottom: 1.6rem;

                        .icon {
                            color: v.$success-color-300;
                            margin-top: .3rem;
                            min-width: 1.8rem;
                        }
                    }
                }
            }
        }

        // Payment box

        &__payment {

            .paymentbox {
                border-radius: 1.2rem;
                background-color: #fcfcfc;
                border: 1px solid v.$grey-color-100;
                width: auto;
                max-width: 39.6rem;
                box-shadow: v.$bottom-shadow;
                padding: 3.2rem;

                @include m.tablet {
                    margin: 5rem 0;
                }

                @include m.tabletS {
                    max-width: 100%;


                    .input__box,
                    .select__field,
                    input {
                        max-width: 100%;
                    }
                }

                @include m.phoneXS {
                    padding: 3.2rem 2rem;
                }

                .infobox{
                    display: flex;
                    gap: .7rem;
                    align-items: center;
                    margin-bottom: 1.1rem;
                    background-color: v.$primary-color-50;
                    border-radius: .3rem;
                    color: v.$primary-color-300;
                    padding: 1px 1rem;
                    @extend .body-text-4-regular;
                }

                .price {
                    background-color: v.$success-color-50;
                    display: table;
                    @extend .display-2-small-bold;
                    padding: 1rem 2rem;
                    color: v.$grey-color-400;
                    border-radius: .6rem;

                    .day {
                        @extend .heading-4-semibold;
                    }
                }

                .dates {
                    margin: 2.5rem 0 1.2rem 0;
                    color: v.$grey-color-300;
                    @extend .body-text-3-regular;
                }

                .payment-reviewbox {
                    margin-top: 3rem;
                }

                .payment-review {
                    display: flex;
                    justify-content: space-between;
                    gap: .5rem;

                    @extend .body-text-3-regular;
                    color: v.$grey-color-400;
                    margin-bottom: 1.2rem;

                    &:first-child {
                        margin-top: 4.7rem;
                    }

                    &:last-child {
                        border-top: 1px solid #E8E7EC;
                        @extend .body-text-2-bold;
                        padding-top: 1.8rem;
                        margin-bottom: 0;
                    }

                    .naira {
                        height: 2.4rem;
                    }
                }
            }

            .emptybox {
                @include m.flexboxz(column, center, center);
                min-height: 30.3rem;

                .title {
                    @extend .body-text-3-semibold;
                    color: v.$grey-color-400;
                    margin: 1.8rem 0 1.5rem 0;
                }

                .text {
                    @extend .body-text-3-regular;
                    color: v.$grey-color-300;
                    margin-bottom: 1.8rem;
                    text-align: center;
                }
            }

            .dates-form{
                .select__box{
                    margin-bottom: 2.5rem;
                }
            }
        }
    }
}