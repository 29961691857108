@use '../abstract/mixins' as m;
@use '../abstract/variables' as v;
@use '../base/typography' as t;

.terms_privacy{
    
    &__header{
        width: 100%;
        min-height: 30rem;
        background-color: v.$secondary-color-50;
        background-image: url('../../assets/images/img/wavebg.png');
        margin-bottom: 10rem;
        
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .heading{
            @extend .display-1-small-semibold;
            color: v.$grey-color-400;
            padding: 1.6rem;
            text-align: center;
        }

        .updated{
            text-align: center;
            padding: .8rem 2rem;
            border-radius: .8rem;
            background-color: v.$secondary-color-100;
            color: v.$secondary-color-400;
        }
    }

    .title{
        @extend .heading-2-semibold;
        color: v.$grey-color-400;
        margin: 3rem 0;
    }

    color: v.$grey-color-400;
}