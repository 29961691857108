@use '../abstract/mixins' as m;
@use '../abstract/variables' as v;
@use '../base/typography' as t;


// Merchant onboarding

.company__logobox {
    @include m.flexboxz(row, flex-start, center);
    gap: 1.8rem;
    margin-bottom: 3.7rem;

    .logo {
        width: 10.4rem;
        height: 10.4rem;
        min-width: 10.4rem;
        border-radius: 50%;
        background-color: v.$grey-color-50;
        border: 4px solid v.$grey-color-100;
        @include m.flexboxz(row, center, center);
        color: v.$grey-color-300;
        font-size: 2.2rem;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .uploadbox {
        @include m.flexboxz(column, flex-start, flex-start);
        gap: 1rem;

        .uploadinput {
            display: none;
        }

        .uploadlabel {
            background-color: v.$secondary-color-50;
            padding: .5rem 1rem;
            border-radius: .4rem;
            color: v.$secondary-color-400;
            @extend .body-text-4-regular;
            cursor: pointer;
            transition: all 0.2s ease-in-out;

            &:hover {
                background-color: v.$secondary-color-100;
            }
        }

        .instruction {
            color: v.$grey-color-400;
            @extend .body-text-4-regular;
        }
    }
}


// Merchant Layout

.merchant__layout {
    width: 100%;
    height: auto;
    min-height: 100vh;
    // background-color: v.$grey-ad2;
    position: relative;

    .main {
        width: calc(100% - 25rem);
        height: auto;
        min-height: 100vh;
        background-color: v.$grey-color-50;
        padding: 9.8rem 2rem 2rem 2rem;
        position: relative;
        left: 25rem;

        @include m.tablet {
            width: 100%;
            position: static;
        }

        &__cover {
            background-color: v.$white-color;
            width: 100%;
            height: auto;
            min-height: calc(100vh - 11.8rem);
            padding: 2.5rem 3rem;
        }
    }
}


// 