@use '../abstract/mixins' as m;
@use '../abstract/variables' as v;
@use '../base/typography' as t;

.help-center{

    &__header{
        width: 100%;
        min-height: 30rem;
        background-color: v.$primary-color-50;
        background-image: url('../../assets/images/img/wavebg.png');
        
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
        
        .heading{
            @extend .heading-1-semibold;
            color: v.$grey-color-400;
            margin-top: 4.9rem
        }

        .subheading{
            @extend .body-text-1-regular;
            color: v.$grey-color-300;
            text-align: center;
            padding: 0 1.6rem;
            margin-bottom: 2rem;
        }
    }

    &__wrapper{
        max-width: 70rem;
        margin: 12rem auto;
        
    }

    .formbox{
        box-shadow: 0px 1px 4px rgba(39, 40, 51, 0.2);
        border-radius: 12px;     
        
        width: 100%;
        max-width: 65.8rem;
        min-height: 57.8rem;
        background-color: v.$white-color;
        padding: 10rem 2rem;
        height: auto;
        margin: -15rem auto 0 auto;

        @include m.phone{
            margin: -10rem 0 0 0;
            box-shadow: none;
            padding: 5rem 2rem;
            min-height: 4rem;
        }

        .formcon{
            margin: 0 auto;
            width: 100%;
            max-width: 41.2rem;
        }
    }
}