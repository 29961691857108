@use '../abstract/mixins' as m;
@use '../abstract/variables' as v;
@use '../base/typography' as t;

.footer{

    background-color: v.$grey-color-50;
    width: 100%;
    height: auto;
    min-height: 42.3rem;
    position: relative;
    margin-top: 15rem;

    &__mt-0{
        margin-top: 0rem!important;
    }
    // background-image: url('../../assets/images/img/footer-wavybg.jpg');
    // background-repeat: no-repeat;
    // background-position: bottom center;

    &-container{
        display: flex;
        justify-content: space-between;
        max-width: v.$container-width;
        margin: 0 auto;
        padding: 7.8rem 2rem 0 2rem;

        // responsive
        @include m.tabletS{
            flex-wrap: wrap;
        }
    }
    
    &__box{
        width: 24%;
        height: auto;
        overflow: auto;
        display: flex;
        flex-direction: column;

        // responsive
        @include m.tabletS{
            width: 50%;
        }

        @include m.tabletS{
            width: 50%;
        }

        @include m.phoneM{
            width: 100%;
            justify-content: center;
            align-items: flex-start;
        }
        
        &--heading{
            @extend .heading-2-bold;
            color: v.$grey-color-400;
        }

        &--nav{
            margin-top: 3.9rem;
            @extend .body-text-2-regular;
            padding: 0;

            @include m.tabletS{
                margin-top: 2.5rem;
            }
                
            li{
                list-style: none;
                margin: 0 0 1.8rem 0;
            }

            .nav_link{
                color: v.$grey-color-400;
                list-style: none;
                text-decoration: none;

                &:hover{
                    color: v.$grey-color-400;
                }
            }
        }

        .social{
            display: flex;
            flex-direction: row;
            gap: 1.8rem;
        }
    }

    .copyright{
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 4rem;
        gap: 1rem;
        color: v.$grey-color-400;
        @extend .body-text-3-regular;

        @include m.tabletS{
            justify-content: flex-start;
            padding-left: 2rem;
            padding-right: 2rem;
        }

        @include m.phoneM{
            margin-top: 2rem;
        }
    }
}