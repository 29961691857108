@use '../abstract/mixins' as m;
@use '../abstract/variables' as v;
@use '../base/typography' as t;

.merchant__sidebar {
    width: 25rem;
    height: auto;
    min-height: 100vh;
    background-color: v.$white-color;
    position: fixed;
    z-index: 120;
    padding: 0 2.5rem;

    &.active{
        left: 0rem;
    }

    @include m.tablet{
        left: -25rem;
    }

    .close{
        @include m.flexboxz(row, center, center);
        padding: .1rem .6rem;
        border-radius: 2.4rem;
        background-color: v.$grey-color-100;
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
        font-size: 1.2rem;
        color: v.$grey-color-300;
        display: none;
        cursor: pointer;
        transition: all .2s ease-in-out;

        @include m.tablet{
            display: inline-block;
        }

        .icon{
            padding-left: .2rem;
            font-size: 1.4rem;
        }
    }

    .logo {
        @include m.flexboxz(row, center, center);
        margin-top: 3.2rem;

        .thelogo {
            height: 3.4rem;
        }
    }

    .welcome {
        @extend .heading-4-semibold;
        color: v.$grey-color-400;
        margin: 3rem 0 .4rem 0;
        text-align: center
    }

    .merchant__id {
        @extend .body-text-3-regular;
        color: v.$grey-color-300;
        text-align: center;
        margin-bottom: 2.1rem
    }

    .nav {
        @include m.flexboxz(column, space-between, flex-start);
        height: auto;
        min-height: calc(100vh - 16.8rem);
        gap: 3rem;

        &__con {
            margin: 0;
            padding: 0;
            list-style: none;
            @include m.flexboxz(column, flex-start, flex-start);
            gap: 1.5rem;
            width: 100%;

            li {
                width: 100%;
            }
        }

        &-link {
            color: v.$grey-color-300;
            padding: 1rem 2.3rem;
            display: flex;
            gap: 1.3rem;
            align-items: center;
            border-radius: .6rem;
            width: 100%;
            font-weight: 400;
            transition: all 0.2s ease-in-out;

            &.active {
                background-color: v.$primary-color-300;
                color: v.$white-color;

                &:hover {
                    color: v.$white-color;
                }
            }

            &:hover {
                color: v.$white-color;
                color: v.$primary-color-400;
            }

            .icon {
                font-size: 1.8rem;
            }
        }
    }


}