@use '../abstract/mixins' as m;
@use '../abstract/variables' as v;
@use '../base/typography' as t;

.getbus {
    width: 100%;
    height: auto;
    min-height: 66.3rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &__con {
        @include m.flexboxz(row, flex-start, center);
        z-index: 10;
        position: relative;

        @include m.tablet {
            flex-direction: column;
            gap: 5rem;
            margin-top: 10rem;
        }

        .textbox {
            width: 50%;
            padding-right: 7rem;

            @include m.tablet {
                width: 80%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                text-align: center;
                padding-right: 0rem;
            }

            @include m.phoneM {
                width: 100%;
            }

            .heading {
                text-transform: capitalize;
                @extend .display-2-bold;
                color: v.$grey-color-400;
                margin-bottom: 5.2rem;

                @include m.phoneM {
                    font-size: 3.2rem;
                }

                .elevate {
                    position: relative;

                    &__icon {
                        position: absolute;
                        bottom: -1.6rem;
                        left: 0;

                        @include m.phoneM {
                            width: 10rem;
                        }
                    }
                }

                .stay {
                    @extend .display-2-regular;
                    background: -webkit-linear-gradient(#064AD7, #38B188);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;

                    @include m.phoneM {
                        font-size: 3.2rem;
                    }
                }
            }

            .subheading {
                color: v.$grey-color-400;
                @extend .heading-3-regular;
                line-height: 3.5rem;
                margin-bottom: 1.8rem;

                .blue {
                    color: v.$primary-color-400;
                    font-weight: 600;
                }
            }

            .actions {
                display: flex;
                align-items: center;
                gap: 2rem;
                padding-top: 3.2rem;

                .busbtn {
                    text-decoration: none;
                    &:hover{
                        color: v.$white-color;
                    }
                }

                @include m.phoneM {
                    flex-direction: column;
                }
            }
        }

        .imagebox {
            width: 50%;

            @include m.tablet {
                width: 100%;
            }

            .rideimage {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-top: 3rem;

                @include m.phoneM {
                    padding-top: 0rem;
                }

                .book-image {
                    width: 100%;

                    @include m.tablet {
                        width: 80%;
                    }

                    @include m.phoneM {
                        width: 100%;
                    }
                }
            }
        }
    }

    .ridelines {
        position: absolute;
        width: 100%;
        z-index: 1;
        bottom: -9rem;
    }
}