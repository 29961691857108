@use '../abstract/mixins' as m;
@use '../abstract/variables' as v;
@use '../base/typography' as t;


.room-upload{

    &__content{
        width: 100%;
        max-width: 98.2rem;
        margin: 8rem auto 0 auto;
        border-radius: 1.2rem;
        border: 1px solid v.$grey-ad;
        padding: 10rem 2rem;

        position: relative;

        .stepper{
            position: absolute;
            top: 3.6rem;
            left: 4.6rem;
            color: v.$primary-color-300;
            @extend .body-text-2-regular;
        }
    }

    .formbox{
        width: 100%;
        max-width: 41.2rem;
        margin: 0 auto;

        .heading{
            @extend .heading-3-semibold;
            color: v.$grey-color-400;
            margin-bottom: 4.2rem;
            text-align: center
        }

        .terms{
            @extend .body-text-5-regular;
            color: v.$grey-color-300;
            text-align: center;
            margin-top: 1.98rem;
        }
    }

    // Image selection section
    &__selection{

        .imagelabel{
            width: 100%;
            min-height: 10rem;
            padding: 4rem 3rem;
            margin-bottom: 4rem;
            border-radius: 0.6rem;
            border: 1px dashed v.$primary-color-200;
            cursor: pointer;
            @include m.flexboxz(column, center, center);

            .text{
                @extend .body-text-2-regular;
                color: v.$grey-color-400;
                

                &-danger{
                    @extend .body-text-4-regular;
                    color: v.$danger-color-200;
                    margin-bottom: .6rem;
                }

                &-small{
                    @extend .body-text-4-regular;
                    color: v.$grey-color-300;
                }
            }

            .imageicon{
                margin-bottom: 1rem;
            }
        }

        .fileInput{
            display: none;
        }
    }

    &__imagecon{
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        gap: 2rem;
        margin-bottom: 1.5rem;

        .imagebox{
            flex: 0 0 auto;
            flex-basis: 200px;
            height: 150px;
            border-radius: .6rem;
            border: 1px solid v.$grey-ad;
            position: relative;

            .image{
                object-fit: contain;
                width: 100%;
                height: 100%;
            }

            .text{
                @extend .body-text-3-regular;
                color: v.$grey-color-300;
            }

            .counter{
                @include m.flexboxz(row, center, center);
                position: absolute;
                bottom: 1.3rem;
                left: 1.3rem;
                background-color: v.$primary-color-50;
                color: v.$grey-color-400;
                box-shadow: v.$box-shadow-5;
                @extend .body-text-3-regular;
                width: 2.5rem;
                height: 2.5rem;
                border-radius: 50%;
            }

            .remove{
                @include m.flexboxz(row, center, center);
                position: absolute;
                top: 1.3rem;
                right: 1.3rem;
                background-color: v.$danger-color-100;
                color: v.$danger-color-400;
                width: 2.5rem;
                height: 2.5rem;
                border-radius: 50%;
                cursor: pointer;
            }
        }
    }

    .successbox{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .image{
            height: 20.7rem;
        }

        .heading{
            @extend .heading-3-semibold;
            color: v.$grey-color-400;
            margin-top: 3.7rem
        }

        .subheading{
            @extend .body-text-2-regular;
            color: v.$grey-color-300;
            margin: 1.2rem 0 2.5rem 0;
            width: 50%;
            text-align: center;
            line-height: 2.7rem;
        }
    }
}