

/*======================================
            Gilroy font family
=======================================*/ 

@font-face {
    font-family: 'Gilroy';
    src: url('../../assets/fonts/gilroy/Gilroy-Black.woff2') format('woff2'),
        url('../../assets/fonts/gilroy/Gilroy-Black.woff') format('woff'),
        url('../../assets/fonts/gilroy/Gilroy-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../assets/fonts/gilroy/Gilroy-Bold.woff2') format('woff2'),
        url('../../assets/fonts/gilroy/Gilroy-Bold.woff') format('woff'),
        url('../../assets/fonts/gilroy/Gilroy-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../assets/fonts/gilroy/Gilroy-BlackItalic.woff2') format('woff2'),
        url('../../assets/fonts/gilroy/Gilroy-BlackItalic.woff') format('woff'),
        url('../../assets/fonts/gilroy/Gilroy-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../assets/fonts/gilroy/Gilroy-BoldItalic.woff2') format('woff2'),
        url('../../assets/fonts/gilroy/Gilroy-BoldItalic.woff') format('woff'),
        url('../../assets/fonts/gilroy/Gilroy-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../assets/fonts/gilroy/Gilroy-Heavy.woff2') format('woff2'),
        url('../../assets/fonts/gilroy/Gilroy-Heavy.woff') format('woff'),
        url('../../assets/fonts/gilroy/Gilroy-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../assets/fonts/gilroy/Gilroy-ExtraBold.woff2') format('woff2'),
        url('../../assets/fonts/gilroy/Gilroy-ExtraBold.woff') format('woff'),
        url('../../assets/fonts/gilroy/Gilroy-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../assets/fonts/gilroy/Gilroy-ExtraBoldItalic.woff2') format('woff2'),
        url('../../assets/fonts/gilroy/Gilroy-ExtraBoldItalic.woff') format('woff'),
        url('../../assets/fonts/gilroy/Gilroy-ExtraBoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../assets/fonts/gilroy/Gilroy-HeavyItalic.woff2') format('woff2'),
        url('../../assets/fonts/gilroy/Gilroy-HeavyItalic.woff') format('woff'),
        url('../../assets/fonts/gilroy/Gilroy-HeavyItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../assets/fonts/gilroy/Gilroy-Light.woff2') format('woff2'),
        url('../../assets/fonts/gilroy/Gilroy-Light.woff') format('woff'),
        url('../../assets/fonts/gilroy/Gilroy-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../assets/fonts/gilroy/Gilroy-LightItalic.woff2') format('woff2'),
        url('../../assets/fonts/gilroy/Gilroy-LightItalic.woff') format('woff'),
        url('../../assets/fonts/gilroy/Gilroy-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../assets/fonts/gilroy/Gilroy-Medium.woff2') format('woff2'),
        url('../../assets/fonts/gilroy/Gilroy-Medium.woff') format('woff'),
        url('../../assets/fonts/gilroy/Gilroy-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../assets/fonts/gilroy/Gilroy-MediumItalic.woff2') format('woff2'),
        url('../../assets/fonts/gilroy/Gilroy-MediumItalic.woff') format('woff'),
        url('../../assets/fonts/gilroy/Gilroy-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../assets/fonts/gilroy/Gilroy-Regular.woff2') format('woff2'),
        url('../../assets/fonts/gilroy/Gilroy-Regular.woff') format('woff'),
        url('../../assets/fonts/gilroy/Gilroy-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy-RegularItalic';
    src: url('../../assets/fonts/gilroy/Gilroy-RegularItalic.woff2') format('woff2'),
        url('../../assets/fonts/gilroy/Gilroy-RegularItalic.woff') format('woff'),
        url('../../assets/fonts/gilroy/Gilroy-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../assets/fonts/gilroy/Gilroy-SemiBold.woff2') format('woff2'),
        url('../../assets/fonts/gilroy/Gilroy-SemiBold.woff') format('woff'),
        url('../../assets/fonts/gilroy/Gilroy-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../assets/fonts/gilroy/Gilroy-SemiBoldItalic.woff2') format('woff2'),
        url('../../assets/fonts/gilroy/Gilroy-SemiBoldItalic.woff') format('woff'),
        url('../../assets/fonts/gilroy/Gilroy-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../assets/fonts/gilroy/Gilroy-Thin.woff2') format('woff2'),
        url('../../assets/fonts/gilroy/Gilroy-Thin.woff') format('woff'),
        url('../../assets/fonts/gilroy/Gilroy-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../assets/fonts/gilroy/Gilroy-UltraLight.woff2') format('woff2'),
        url('../../assets/fonts/gilroy/Gilroy-UltraLight.woff') format('woff'),
        url('../../assets/fonts/gilroy/Gilroy-UltraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../assets/fonts/gilroy/Gilroy-UltraLightItalic.woff2') format('woff2'),
        url('../../assets/fonts/gilroy/Gilroy-UltraLightItalic.woff') format('woff'),
        url('../../assets/fonts/gilroy/Gilroy-UltraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../assets/fonts/gilroy/Gilroy-ThinItalic.woff2') format('woff2'),
        url('../../assets/fonts/gilroy/Gilroy-ThinItalic.woff') format('woff'),
        url('../../assets/fonts/gilroy/Gilroy-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}




/*======================================
        Google sans font family
=======================================*/ 

@font-face {
    font-family: 'Google Sans';
    src: url('../../assets/fonts/google-sans/GoogleSans-Bold.woff2') format('woff2'),
        url('../../assets/fonts/google-sans/GoogleSans-Bold.woff') format('woff'),
        url('../../assets/fonts/google-sans/GoogleSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Google Sans';
    src: url('../../assets/fonts/google-sans/GoogleSans-Italic.woff2') format('woff2'),
        url('../../assets/fonts/google-sans/GoogleSans-Italic.woff') format('woff'),
        url('../../assets/fonts/google-sans/GoogleSans-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Google Sans';
    src: url('../../assets/fonts/google-sans/GoogleSans-BoldItalic.woff2') format('woff2'),
        url('../../assets/fonts/google-sans/GoogleSans-BoldItalic.woff') format('woff'),
        url('../../assets/fonts/google-sans/GoogleSans-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Google Sans';
    src: url('../../assets/fonts/google-sans/GoogleSans-Regular.woff2') format('woff2'),
        url('../../assets/fonts/google-sans/GoogleSans-Regular.woff') format('woff'),
        url('../../assets/fonts/google-sans/GoogleSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Google Sans';
    src: url('../../assets/fonts/google-sans/GoogleSans-MediumItalic.woff2') format('woff2'),
        url('../../assets/fonts/google-sans/GoogleSans-MediumItalic.woff') format('woff'),
        url('../../assets/fonts/google-sans/GoogleSans-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Google Sans';
    src: url('../../assets/fonts/google-sans/GoogleSans-Medium.woff2') format('woff2'),
        url('../../assets/fonts/google-sans/GoogleSans-Medium.woff') format('woff'),
        url('../../assets/fonts/google-sans/GoogleSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

