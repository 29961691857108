@use '../abstract/variables' as v;
@use '../base/typography' as t;

.zbtn{
    outline: none;
    background: none;
    border: none;
    border-radius: .5rem;

    // Primary buttons
    &-primary{

        color: v.$white-color;
        background-color: v.$primary-color-300;
        border: 1px solid v.$primary-color-300;
        transition: all 0.3s ease-in-out;

        &:hover{
            background-color: v.$primary-color-400;
            border: 1px solid v.$primary-color-400;
            box-shadow: v.$box-shadow-blue;
        }

        &:disabled{
            background-color: v.$primary-color-100;
            border: 1px solid v.$primary-color-100;
            color: v.$disabled-color;
            box-shadow: none;
        }

        &-sm{
            @extend .zbtn-primary;
            padding: .4rem 2.5rem;
            @extend .body-text-3-semibold;
        }

        &-def{
            @extend .zbtn-primary;
            padding: .9rem 2.5rem;
            @extend .body-text-3-semibold;
        }

        &-md{
            @extend .zbtn-primary;
            padding: 1.55rem 3.5rem;
            @extend .body-text-2-semibold;
        }

        &-lg{
            @extend .zbtn-primary;
            padding: 2.4rem 5.5rem;
            @extend .heading-2-semibold;
        }
    }

    &-primary{

        // Primary outline button
        &-outline{
            color: v.$primary-color-300;
            background-color: v.$white-color;
            transition: all 0.3s ease-in-out;
            border: 1px solid v.$primary-color-200;

            &:hover{
                border: 1px solid v.$primary-color-400;
                color: v.$primary-color-400;
            }
    
            &:disabled{
                background-color: v.$primary-color-100;
                border: 1px solid v.$primary-color-100;
                color: v.$disabled-color;
                box-shadow: none;
            }
    
            &-sm{
                @extend .zbtn-primary-outline;
                padding: .4rem 2.5rem;
                @extend .body-text-3-semibold;
            }
    
            &-def{
                @extend .zbtn-primary-outline;
                padding: .9rem 2.5rem;
                @extend .body-text-3-semibold;
            }
    
            &-md{
                @extend .zbtn-primary-outline;
                padding: 1.55rem 3.5rem;
                @extend .body-text-2-semibold;
            }
    
            &-lg{
                @extend .zbtn-primary-outline;
                padding: 2.4rem 5.5rem;
                @extend .heading-2-semibold;
            }
        }
    }

    // Secondary buttons
    &-secondary{

        color: v.$white-color;
        background-color: v.$secondary-color-300;
        border: 1px solid v.$secondary-color-300;
        transition: all 0.3s ease-in-out;

        &:hover{
            background-color: v.$secondary-color-400;
            border: 1px solid v.$secondary-color-400;
            box-shadow: v.$box-shadow-green;
        }

        &:disabled{
            background-color: v.$secondary-color-100;
            border: 1px solid v.$secondary-color-100;
            color: v.$disabled-color;
            box-shadow: none;
        }

        &-sm{
            @extend .zbtn-secondary;
            padding: .4rem 2.5rem;
            @extend .body-text-3-semibold;
        }

        &-def{
            @extend .zbtn-secondary;
            padding: .9rem 2.5rem;
            @extend .body-text-3-semibold;
        }

        &-md{
            @extend .zbtn-secondary;
            padding: 1.55rem 3.5rem;
            @extend .body-text-2-semibold;
        }

        &-lg{
            @extend .zbtn-secondary;
            padding: 2.4rem 5.5rem;
            @extend .heading-2-semibold;
        }
    }

    &-secondary{

        // secondary outline button
        &-outline{
            color: v.$secondary-color-300;
            background-color: v.$white-color;
            transition: all 0.3s ease-in-out;
            border: 1px solid v.$secondary-color-200;

            &:hover{
                border: 1px solid v.$secondary-color-400;
                color: v.$secondary-color-400;
            }
    
            &:disabled{
                background-color: v.$secondary-color-100;
                border: 1px solid v.$secondary-color-100;
                color: v.$disabled-color;
                box-shadow: none;
            }
    
            &-sm{
                @extend .zbtn-secondary-outline;
                padding: .4rem 2.5rem;
                @extend .body-text-3-semibold;
            }
    
            &-def{
                @extend .zbtn-secondary-outline;
                padding: .9rem 2.5rem;
                @extend .body-text-3-semibold;
            }
    
            &-md{
                @extend .zbtn-secondary-outline;
                padding: 1.55rem 3.5rem;
                @extend .body-text-2-semibold;
            }
    
            &-lg{
                @extend .zbtn-secondary-outline;
                padding: 2.4rem 5.5rem;
                @extend .heading-2-semibold;
            }
        }
    }

    &-white{
        color: v.$grey-color-300;
        background-color: v.$white-color;
        border: 1px solid v.$grey-color-200;
        transition: all 0.3s ease-in-out;
        padding: .9rem 1.5rem;

        .icon{
            margin-right: 1rem;
        }
    }

    &-block{
        width: 100%;
    }


    &-extend{
        color: v.$secondary-color-300;
        @extend .body-text-4-semibold;
        background-color: v.$white-color;
        padding: .8rem 1.2rem;
        transition: all 0.3s ease;
        border: 1px solid v.$secondary-color-100;

        &:hover{
            color: v.$secondary-color-400;
            border: 1px solid v.$secondary-color-200;
        }
    }
}