@use '../abstract/mixins' as m;
@use '../abstract/variables' as v;
@use '../base/typography' as t;

.faq{

    &__header{
        width: 100%;
        min-height: 30rem;
        background-color: v.$secondary-color-50;
        background-image: url('../../assets/images/img/wavebg.png');
        
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .heading{
            @extend .display-1-small-semibold;
            color: v.$grey-color-400;
            padding: 1.6rem;
            text-align: center;
        }
    }

    &__wrapper{
        max-width: 70rem;
        margin: 12rem auto 5rem auto;
    }
}