@use '../abstract/mixins' as m;
@use '../abstract/variables' as v;
@use '../base/typography' as t;

.profile {

    &__header {
        width: 100%;
        height: auto;
        min-height: 15rem;
        background-color: v.$primary-color-50;

        @include m.phoneM {
            min-height: 10rem;
            margin-bottom: 3rem;
        }

        .heading {
            @extend .heading-4-semibold;
            font-size: 1.8rem !important;
            color: v.$grey-color-300;
            padding-top: 5rem;
            margin: 0;

            span {
                @extend .heading-4-semibold;
                font-size: 1.8rem !important;
                color: v.$grey-color-400;
            }
        }
    }

    &__pull {
        margin-top: -3.5rem;

        @include m.phoneM {
            margin-top: 0;

            .tabs__nav {
                flex-direction: column;
            }
        }
    }

    &__form {
        .heading {
            @extend .body-text-1-semibold;
            color: v.$grey-color-400;
            margin-bottom: .2rem;
        }

        .subheading {
            @extend .body-text-3-regular;
            color: v.$grey-color-300;
        }
    }

    &__space {
        margin-bottom: 2.5rem;

        @include m.tabletS {
            margin-bottom: 1.6rem;

            .mobile__space {
                margin-bottom: 4rem;
            }
        }

        .input__box {
            max-width: 41.2rem;
        }

        .infotext {
            @extend .body-text-5-regular;
            color: v.$grey-color-300;
            display: flex;
            align-items: center;
            gap: .6rem;
            margin-top: -3rem;
            padding-bottom: 3rem;
        }
    }

    // Personal details tab section
    .personal-details {

        .avatarbox {
            width: 100%;
            padding: 4.9rem 0 6.8rem 0;
            display: flex;
            align-items: center;
            gap: 3rem;

            @include m.phoneM {
                flex-direction: column;
            }

            .profile-picture {
                width: 10rem;
                height: 10rem;
                border-radius: 50%;
                border: 4px solid v.$white-color;
                background-color: v.$grey-color-100;
                filter: drop-shadow(0px 2px 4px rgba(39, 40, 51, 0.2));

                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;

                .image {
                    width: 10rem;
                    height: 10rem;
                    object-fit: cover;

                }

                .icon {
                    color: v.$grey-color-300;
                }
            }

            .profile-content {

                display: flex;
                flex-direction: column;
                align-items: flex-start;

                @include m.phoneM {
                    align-items: center;
                }

                .profile-input {
                    display: none;
                }

                .upload-badge {
                    background-color: v.$secondary-color-50;
                    color: v.$secondary-color-400;
                    padding: .4rem 1rem;
                    border-radius: .4rem;
                    cursor: pointer;
                }

                .text {
                    @extend .body-text-4-regular;
                    color: v.$grey-color-300;
                    margin-top: 1rem;

                    @include m.phoneM {
                        text-align: center;
                    }
                }
            }
        }
    }

    // Security tab section

    .security {

        &__title {
            color: v.$grey-color-200;
            @extend .heading-2-semibold;
            padding: 7rem 0 6rem 0;
        }

    }


    .earnings {

        &__conbox {
            display: flex;
            gap: 1.5rem;

            @include m.tablet {
                flex-wrap: wrap;
            }

        }

        &__overview {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 2rem;
            max-width: 40rem;
            width: 100%;

            .total__earnings {
                display: flex;
                flex-direction: column;
                gap: 1.2rem;

                .title {
                    color: v.$primary-color-300;
                    @extend .body-text-3-semibold;
                    margin: 0;
                    @include m.flexboxz(row, flex-start, flex-end);
                    gap: 1rem;

                    .eyeicon {
                        cursor: pointer;

                        .icon {
                            color: v.$grey-color-300 !important;
                        }
                    }
                }

                .value {
                    @extend .display-1-small-bold;
                    color: v.$grey-color-400;
                    margin: 0;
                    position: relative;
                    padding: 0 2.4rem 0 2rem;

                    &.novalue {
                        padding: 0 !important;
                    }

                    .naira {
                        position: absolute;
                        top: 0;
                        left: 0;
                    }

                }
            }
        }

        &__info {
            width: 100%;
            max-width: 40rem;
            height: auto;
        }

        &__box {
            flex-basis: 25%;
            min-height: 17rem;
            box-shadow: v.$box-shadow-6;
            background-color: v.$white-color;
            padding: 2.4rem 3rem;
            border-radius: .6rem;

            @include m.tablet {
                flex-basis: 48.6%;
            }

            @include m.phone {
                flex-basis: 100%;
            }

            .titlebox {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-direction: row;
                gap: 1.8rem;
                margin-bottom: 2.6rem;

                .iconbox {
                    width: 3.5rem;
                    height: 3.5rem;
                    border-radius: .6rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &.green {
                        background-color: v.$secondary-color-100;
                        color: v.$secondary-color-400;
                    }

                    &.yellow {
                        background-color: v.$warning-color-100;
                        color: v.$warning-color-400;
                    }

                    &.blue {
                        background-color: v.$info-color-100;
                        color: v.$info-color-400;
                    }
                }

                .text {
                    color: v.$grey-color-300;
                    @extend .body-text-2-semibold;
                    margin: 0;
                }

                .textcount {
                    color: v.$grey-color-400;
                    @extend .display-1-bold;
                    margin: 0;
                }
            }


            .value {
                @extend .display-2-small-bold;
                color: v.$grey-color-400;
            }
        }

        .overview {
            color: v.$grey-color-200;
            @extend .heading-2-semibold;
            padding: 5.4rem 0 2.6rem 0;
        }

        .account-details {
            @extend .overview;
            padding: 8rem 0 5.5rem 0;
        }

        // Debit card section

        .debit-card {
            width: 100%;
            max-width: 51.7rem;
            border-radius: 1.2rem;
            height: 30rem;
            padding: 4.5rem 3.2rem;

            background-color: v.$secondary-color-200;
            background-image: url('../../assets/images/img/debit-card.jpg');
            background-size: cover;

            @include m.flexboxz(column, space-between, flex-start);

            color: v.$white-color;


            &__wrapper {
                @include m.flexboxz(row, space-between, flex-end);
                width: 100%;
                gap: 1.5rem;

                .contentbox {
                    display: flex;
                    flex-direction: column;
                    gap: .5rem;

                    &:nth-child(even) {
                        align-items: flex-end;
                    }

                    .text:nth-child(even) {
                        @extend .heading-3-semibold;
                    }
                }
            }
        }

        .balancebox {
            @include m.flexboxz(column, center, center);
            gap: 1.4rem;

            .title {
                color: v.$grey-color-400;
                text-align: center;
                padding: 4.2rem 1rem 1rem 1rem;
                @extend .heading-2-semibold;
            }

            .subtitle {
                color: v.$grey-color-400;
                background-color: v.$primary-color-50;
                padding: .5rem 1rem;
                border-radius: .6rem;
            }

            .balance {

                @extend .display-1-small-semibold;
                color: v.$grey-color-400;

                img {
                    padding-right: 1rem;
                }
            }
        }

        .alignme {
            margin: 2.3rem auto 0 auto;
            width: 41.2rem;
        }

        .add-bank-details {
            width: 100%;
            @include m.flexboxz(column, center, center);
            max-width: 40rem;
            height: 19.7rem;
            border-radius: 1.2rem;
            border: 1px dashed v.$primary-color-300;
            border-spacing: 2px;
            gap: 1.3rem;
            padding: 2rem;
            cursor: pointer;

            transition: all 0.3s ease-in-out;

            &:hover {
                box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
            }

            .iconbox {
                @include m.flexboxz(row, center, center);
                background-color: v.$primary-color-50;
                border-radius: 50%;
                width: 5rem;
                height: 5rem;
                border: 1px solid v.$primary-color-300;

                .icon {
                    color: v.$primary-color-300;
                }
            }

            .text {
                color: v.$primary-color-300;
                background-color: v.$primary-color-50;
                padding: .3rem .7rem;
                font-size: 1.4rem;
                font-weight: 400;
                font-family: "Google Sans";
            }
        }
    }

    .merchant__linkbox {
        width: 100%;
        max-width: 41.2rem;
        min-height: 9.3rem;
        background-color: v.$primary-color-50;
        border: 1px solid v.$primary-color-200;
        border-radius: .6rem;
        padding: 0 2.5rem;

        @include m.flexboxz(row, space-between, center);
        gap: 1rem;

        .link {
            @include m.flexboxz(row, flex-start, center);
            gap: 1.7rem;

            &__image {
                width: 3rem;
                height: 3rem;
                border-radius: 50%;
                background-color: v.$grey-color-300;
                overflow: hidden;

                .image {
                    width: 3rem;
                    max-width: 3rem;
                    height: 3rem;
                    object-fit: cover;
                }
            }

            &__text {

                .title {
                    margin: 0;
                    color: v.$grey-color-400;
                    @extend .body-text-2-semibold;
                }

                .subtitle {
                    margin: 0;
                    color: v.$grey-color-300;
                    @extend .body-text-4-regular;
                }
            }
        }

        .copy {
            cursor: pointer;

            &__icon {
                color: v.$primary-color-200;
                stroke-width: 1px;
            }

            &__checked {
                color: v.$secondary-color-200;
                stroke-width: 1px;
            }
        }
    }

    .instructions {
        width: 80%;
    }

    // Referrals Sections

    .referrals {
        .heading {
            @extend .heading-2-semibold;
            color: v.$grey-color-200;
            padding: 5.4rem 0 3.9rem 0;
        }

        &__boxcon {
            width: 100%;
            height: auto;
            display: flex;
            gap: 4.5rem;

            @include m.tabletS {
                flex-direction: column;
            }
        }

        &__box {
            width: 30%;
            min-height: 15.5rem;
            height: 15.5rem;
            border-radius: 1.2rem;
            background-color: v.$white-color;
            padding: 2.3rem 2.8rem;
            display: flex;
            flex-direction: column;
            gap: 3rem;
            box-shadow: 0rem 1.3rem 7.4rem .8rem rgba(v.$black-color, 0.05);

            @include m.tablet {
                width: 48.6%;
                height: auto;
            }

            @include m.tabletS {
                width: 100%;
                height: auto;
            }

            .titlebox {
                display: flex;
                gap: 1.2rem;
                justify-content: flex-start;
                align-items: center;

                .text {
                    margin: 0;
                    color: v.$grey-color-300;
                    @extend .body-text-2-semibold;
                }
            }

            .value {
                @extend .display-2-small-bold;
                color: v.$grey-color-400;

                .naira {
                    margin-top: -1rem;
                    margin-right: .5rem;
                    height: 4.5rem;
                }
            }
        }

        &__giftbox {
            width: 30%;
            min-height: 15.5rem;
            height: 15.5rem;
            border-radius: 1.2rem;
            background-color: v.$primary-color-50;
            padding: 2.3rem 2.8rem;
            display: flex;
            gap: 3rem;
            justify-content: space-between;
            box-shadow: 0rem 1.3rem 7.4rem .8rem rgba(v.$black-color, 0.05);

            @include m.tablet {
                width: 48.6%;
                height: auto;
            }

            @include m.tabletS {
                width: 100%;
                height: auto;
            }

            .actionbox {
                display: flex;
                flex-direction: column;
                gap: 2rem;

                .words {
                    .title {
                        color: v.$primary-color-300;
                        margin: 0;
                        @extend .body-text-2-semibold;
                    }

                    .subtitle {
                        color: v.$grey-color-300;
                        font-size: 1.2rem;

                        .amount {
                            font-weight: bold;
                        }
                    }
                }
            }

            .image {
                height: 6.5rem;
            }
        }

        &__listcon {
            padding: 5.4rem 0 0 0;

            .header-text {
                color: v.$grey-color-400;
                @extend .body-text-2-semibold;

                .counter {
                    background-color: v.$secondary-color-50;
                    border-radius: .6rem;
                    padding: .7rem .7rem;
                    color: v.$secondary-color-400;
                }
            }

            .listbox {
                filter: drop-shadow(0 1.3rem 7.4rem rgba(v.$black-color, 0.05));
                width: 100%;
                height: auto;
                max-width: 77rem;
                padding: 3.8rem 3rem;
                background-color: v.$white-color;
                margin-top: 1.5rem;
                min-height: 40rem;

                @include m.tablet {
                    max-width: 100%;
                }

                @include m.tabletS {
                    margin-bottom: 2rem;
                }

                .listrow {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: .9rem 2.7rem;
                    border-radius: 1.1rem;

                    &:hover {
                        background-color: v.$grey-color-50;
                    }

                    @include m.tabletS {
                        flex-direction: column;
                        align-items: flex-start;
                        gap: .5rem;
                    }

                    .namebox {
                        display: flex;
                        gap: 2rem;
                        align-items: center;
                        width: 31%;

                        .names{
                            text-transform: capitalize;
                        }

                        .profile-image {
                            width: 2.6rem;
                            height: 2.6rem;
                            min-width: 2.6rem;
                            border-radius: 50%;

                        }
                    }

                    .status {
                        display: flex;
                        gap: .5rem;
                        border-radius: .6rem;
                        padding: .5rem 2.3rem;
                        font-size: 1.2rem;
                        height: 2.8rem;
                        align-items: center;

                        &.green {
                            background-color: v.$secondary-color-50;
                            color: v.$secondary-color-400;

                            img{
                                height: 1.3rem;
                            }
                        }

                        &.blue {
                            background-color: v.$primary-color-50;
                            color: v.$primary-color-400;
                        }
                    }

                    .price{
                        width: 6rem;
                        text-align: center;
                    }

                    .date{
                        width: 16.5rem;
                    }
                }

                .emptystate{
                    display: flex;
                    min-height: 40rem;
                    flex-direction: column;
                    gap: 1.6rem;
                    align-items: center;
                    justify-content: center;

                    .wordbox{
                        text-align: center;
                        .title{
                            color: v.$grey-color-400;
                            @extend .body-text-2-semibold;
                        }
                        .subtitle{
                            color: v.$grey-color-400;
                            @extend .body-text-2-regular;
                        }
                    }
                }
            }
        }

        &__infobox{
            width: 100%;
            max-width: 48.4rem;
            display: flex;
            gap: 0rem;
            margin-top: 5rem;
            .color{
                font-weight: bold;
            }
        }

        &__modal {
            .header {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .title {
                    @extend .heading-2-semibold;
                    color: v.$grey-color-400;
                    padding: 2.4rem;
                }
            }

            .earnstep {
                width: 100%;
            }

            .info {
                text-align: center;
                @extend .body-text-4-regular;
                padding: 3.4rem 1rem;

                span {
                    font-weight: bold;
                }
            }

            .linkbox {
                width: 100%;
                display: flex;
                justify-content: space-between;
                padding: 1.2rem 4rem;
                background-color: v.$primary-color-50;
                color: v.$primary-color-300;
                gap: 3rem;
                flex-wrap: wrap;

                @include m.phone{
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 1.5rem;
                    padding: 2rem 3rem;
                }

                .code {
                    @include m.phone{
                        font-weight: normal;
                    }
                }

                .copy {
                    cursor: pointer;
                    @include m.phone{
                        font-weight: bold;
                    }
                }
            }
        }
    }

}


//Earnings Empty state
.empty-statecon {
    width: 100%;
    height: auto;
    min-height: 50rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include m.phoneM {
        min-height: 20rem;
        padding: 8rem 0;
    }

    .title {
        @extend .heading-2-semibold;
        color: v.$grey-color-400;
        margin-bottom: .8rem;
        text-align: center
    }

    .subtitle {
        @extend .body-text-2-regular;
        color: v.$grey-color-300;
        margin-bottom: 3.4rem;
        text-align: center;
    }

    .cta {
        display: flex;
        gap: 3.2rem;
        align-items: center;

        @include m.phoneM {
            flex-direction: column;
            gap: 1.6rem;
        }
    }
}

.section-seperator {
    color: v.$grey-color-200;
    @extend .heading-2-semibold;
    border-bottom: 1px solid v.$grey-color-100;
    line-height: 2rem;
    padding: 2.5rem 0 2rem 0;
    margin-bottom: 3rem;
}