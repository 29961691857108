/* --------------------------
        Color Definition 
---------------------------*/ 

// Primary colors

$primary-color-50: #F7FAFE;
$primary-color-100: #CDDBF7;
$primary-color-200: #83A4EB;
$primary-color-300: #074AD7;
$primary-color-400: #003DBB;

// Secondary colors

$secondary-color-50: #EBF7F3;
$secondary-color-100: #D7EFE7;
$secondary-color-200: #9BD8C3;
$secondary-color-300: #38B188;
$secondary-color-400: #118E64;

// Success colors

$success-color-50: #ECFDF5;
$success-color-100: #D1FAE5;
$success-color-200: #A7F3D0;
$success-color-300: #6EE7B7;
$success-color-400: #34D399;

// Danger colors

$danger-color-50: #FEE2E2;
$danger-color-100: #FECACA;
$danger-color-200: #FCA5A5;
$danger-color-300: #F87171;
$danger-color-400: #EF4444;

// Info colors

$info-color-50: #EFF6FF;
$info-color-100: #DBEAFE;
$info-color-200: #BFDBFE;
$info-color-300: #93C5FD;
$info-color-400: #60A5FA;

// Warning colors

$warning-color-50: #FFFBEB;
$warning-color-100: #FEF3C7;
$warning-color-200: #FDE68A;
$warning-color-300: #FCD34D;
$warning-color-400: #FBBF24;

// Grey colors

$grey-color-50: #F8F8F9;
$grey-color-100: #F1F2F3;
$grey-color-200: #BABCC4;
$grey-color-300: #757A89;
$grey-color-400: #444C66;
$grey-ad: #D0D5DE;
$grey-ad2: #E5E5E5;

// Neutral colors

$black-color: #000000;
$black-sub: #2D2D2D;
$white-color: #ffffff;

// Addon colors

$blue-ad: #2A53A9;
$disabled-color: #F3F5FE;


// Merchant colors



/* --------------------------
      Shadows Definition 
---------------------------*/ 

$box-shadow-1: 5px 10px 20px rgba(0, 0, 0, 0.2);
$box-shadow-2: 1px 5px 20px rgba(0, 0, 0, 0.12);
$box-shadow-3: 0px 59px 51px -34px rgba(0, 0, 0, 0.1);
$box-shadow-4: 0px 20px 50px rgba(0, 0, 0, 0.12);
$box-shadow-5: 0px 0.5px 3px rgba(231, 231, 231, 0.05);
$box-shadow-6: 0px 13px 74px 8px rgba(0, 0, 0, 0.05);
$box-shadow-7: 1px 5px 20px rgba(0, 0, 0, 0.12);
$box-shadow-8: 0px 1px 4px rgba(39, 40, 51, 0.2);
$bottom-shadow: inset 0px -1px 0px #E6E8F0;

$box-shadow-blue: 0 8px 12px -1px rgb(6 74 215 / 14%);
$box-shadow-green: 0 8px 12px -1px rgb(17 142 100 / 14%);
$box-shadow-toast: 0px 3px 25px 6px rgb(0 0 0 / 5%);
// $box-shadow-toast: 0px 13px 74px 8px rgba(0, 0, 0, 0.05);



// generic

$container-width: 123rem;

// fonts

$gilroy: "Gilroy";
$gsans: "Google Sans";

$font-family-base: "Google Sans";