@use '../abstract/mixins' as m;
@use '../abstract/variables' as v;
@use '../base/typography' as t;

.homepage {

    .hero {
        width: 100%;
        min-height: 45rem;
        background-image: url('../../assets/images/img/hero-banner.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        margin-top: 1.6rem;
        background-color: v.$grey-color-100;
        padding: 13.5rem 8rem 0 8rem;
        border-radius: 1.5rem;
        overflow: hidden;

        @include m.tablet {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 0rem 8rem;
        }

        @include m.phone {
            padding: 1rem 6rem;
        }

        &__content {
            color: v.$white-color;
        }

        &__title {
            @extend .display-1-semibold;
            color: v.$white-color;

            &--big {
                font-size: 6.8rem;
                font-weight: bold;
                font: v.$gilroy;
                margin: 1.6rem 0;
            }

            &--underline {
                position: relative;
                z-index: 0;

                &::before {
                    content: '';
                    width: 100%;
                    height: 2.2rem;
                    background-color: v.$primary-color-200;
                    position: absolute;
                    bottom: .6rem;
                    z-index: -1;
                    transition: all 0.4s ease-in-out;
                }
            }

            &--underline:hover &--underline::before {
                height: 3.2rem;
            }
        }

        &__subtitle {
            color: v.$white-color;
            margin-top: 1.6rem !important;
        }
    }

    .home-searchbox {
        width: 100%;
        max-width: calc(v.$container-width - 16rem);
        margin: -8rem auto 10rem auto;
        min-height: 15rem;
        height: auto;
        background-color: v.$white-color;
        box-shadow: v.$box-shadow-3;
        border-radius: .6rem;
        display: grid;
        grid-template-columns: 28% 28% 28% 11.5%;
        padding: 4.5rem 4.3rem;
        gap: 1.5rem;

        @include m.tablet {
            grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
            gap: 3.5rem;
            margin: 6rem auto 10rem auto;
            padding: 2.5rem 2.3rem 4.5rem 2.3rem;

            .search {
                display: inline !important;
                margin-right: 1rem;
            }

            .search-icon {
                font-size: 1.5rem;
            }
        }

        @include m.phone {
            grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));

            input {
                max-width: 100% !important;
            }
        }

        .input__box,
        .select__box {
            margin-bottom: 0 !important;
        }

        .search-icon {
            color: v.$white-color;
        }

        .block {
            width: 100%;
            height: 5.5rem;
        }

        .search {
            display: none;
        }

    }

    .header__titlebox {
        @include m.flexboxz(row, space-between, center);
        padding-bottom: 5rem;

        &--left {

            .title {
                @extend .display-1-small-semibold;
                margin-bottom: 1.2rem !important;
                color: v.$grey-color-400;

            }

            .subtilte {
                @extend .body-text-1-regular;
                color: v.$grey-color-400;
            }
        }

        &--right {

            .navigation {
                @include m.flexboxz(row, center, center);
                gap: 3rem;

                .round {
                    width: 5rem;
                    height: 5rem;
                    border: 1px solid v.$grey-color-200;
                    border-radius: 50%;
                    background-color: v.$white-color;
                    @include m.flexboxz(row, center, center);
                    transition: all 0.3s ease-in-out;
                    cursor: pointer;

                    .icon {
                        font-size: 1.6rem;
                        color: v.$grey-color-200;
                        transition: all 0.3s ease-in-out;
                    }

                    &:hover {
                        border: 1px solid v.$secondary-color-300;
                        background-color: v.$secondary-color-300;
                    }

                    &:hover .icon {
                        color: v.$white-color;
                    }
                }
            }
        }
    }

    .features {
        &-header {
            color: v.$grey-color-400;
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-top: 9rem;
            padding: 0 2rem;

            .title {
                @extend .display-1-small-semibold;
                text-align: center
            }

            .subtitle {
                @extend .body-text-1-regular;
                text-align: center;
            }
        }

        &-nbox {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 5rem;
            padding: 7.6rem 0 15rem 0;
        }

        &-box {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 5rem;
            padding: 7.6rem 0 15rem 0;

            @include m.tablet {
                gap: 2rem;
            }

            @include m.tabletS {
                grid-template-columns: repeat(2, 1fr);
            }

            @include m.phone {
                grid-template-columns: 1fr;
                gap: 3.5rem;
                padding: 7.6rem 0 10rem 0;
            }


            .imagebox {
                color: v.$white-color;
                background-color: v.$grey-color-100;
                background-image: url('../../assets/images/img/happy-lady.jpg');
                background-repeat: no-repeat;
                background-size: cover;
                grid-row: span 2;
                overflow: hidden;
                border-radius: 1.2rem;

                @include m.tabletS {
                    display: none;
                }
            }

            .contentbox {
                border-radius: 1.2rem;
                padding: 2.2rem 2.8rem;
                height: 25.3rem;
                position: relative;

                .role {
                    position: absolute;
                    padding: .2rem .8rem;
                    background-color: v.$secondary-color-50;
                    border-radius: .4rem;
                    top: 1rem;
                    right: 1rem;
                    @extend .body-text-4-bold;
                    color: v.$secondary-color-400;
                    text-transform: uppercase;
                }

                @include m.tablet {
                    height: 28rem;
                }

                @include m.phone {
                    height: 25.5rem;
                }

                .title {
                    @extend .heading-4-semibold;
                    color: v.$grey-color-400;
                    padding: 1.5rem 0 1.8rem 0;
                }

                .subtitle {
                    @extend .body-text-3-regular;
                    color: v.$grey-color-300;
                }

                &.green {
                    border: 1px solid v.$secondary-color-300;
                    background-color: v.$secondary-color-50;
                }

                &.blue {
                    border: 1px solid v.$primary-color-100;
                    background-color: v.$primary-color-50;
                }

                &.grey {
                    border: 1px solid v.$grey-color-200;
                    background-color: v.$grey-color-50;
                }

                &.white {
                    border: 1px solid v.$grey-ad;
                    background-color: v.$white-color;
                }
            }
        }
    }

    .getride {
        @include m.flexboxz(row, space-between, center);
        margin-bottom: 14.5rem;

        @include m.tablet {
            flex-direction: column;
            gap: 10rem;
        }

        &__textcon {
            width: 50%;

            @include m.tablet {
                width: 80%
            }

            @include m.phoneS {
                width: 100%
            }

            .heading {
                @extend .display-2-bold;
                color: v.$grey-color-400;
                margin-bottom: 2.2rem;

                @include m.phoneS {
                    font-size: 3.2rem;
                }

                .green {
                    color: v.$secondary-color-400;
                }
            }

            .subtitle {
                font-family: "gilroy";
                font-size: 1.8rem;
                line-height: 3.5rem;
                padding-bottom: 5.3rem;
            }

            .reasons {
                display: flex;
                flex-direction: column;
                gap: 3rem;
                padding-bottom: 5.2rem;

                &__box {
                    display: flex;
                    gap: 1.8rem;
                    @extend .heading-3-semibold;
                    color: v.$grey-color-400;

                    .icon {
                        color: v.$secondary-color-200;
                    }
                }
            }


            .actions {
                display: flex;
                gap: 3rem;
            }
        }

        &__imagecon {
            width: 50%;
            display: flex;
            justify-content: flex-end;

            @include m.tablet {
                width: 80%;
                justify-content: center;
            }

            @include m.phone {
                width: 100%;
            }

            .image {
                width: 80%;

                @include m.tablet {
                    width: 80%;
                }

                @include m.phone {
                    width: 100%;
                }
            }
        }
    }

}


// Listing component style

.listing {
    &-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 2rem;
        // justify-content: space-between;

        @include m.tablet {
            flex-wrap: wrap;
            row-gap: 3rem;
        }

        @include m.phone {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .empty-state {
            width: 100%;
            min-height: 30rem;
            @include m.flexboxz(column, center, center);


            .title {
                color: v.$grey-color-400;
                @extend .heading-3-semibold;
                margin-bottom: .5rem;
            }

            .subtitle {
                color: v.$grey-color-300;
                @extend .body-text-3-regular;
            }

            .click {
                margin-top: 2.8rem;
            }
        }


    }

    &-wrapper-scroll {
        display: flex;
        overflow: hidden;
        gap: 2.39rem;
        padding: 0rem 0 2.5rem 0;
        // justify-content: space-between;
        scroll-snap-type: inline mandatory;

        &>* {
            flex-shrink: 0;
            scroll-snap-align: start;
        }

        // @include m.tablet {
        //     flex-wrap: wrap;
        //     row-gap: 3rem;
        // }

        // @include m.phone {
        //     flex-direction: column;
        //     justify-content: center;
        //     align-items: center;
        // }

        .listing-box {
            box-shadow: 0px 4px 15px rgb(0 0 0 / 6%) !important;
            // box-shadow: 0px 8px 15px rgb(0 0 0 / 8%) !important;
        }
    }

    &-box {
        min-height: 39.7rem;
        box-shadow: v.$box-shadow-4;
        background-color: v.$white-color;
        width: 23.5%;
        border-radius: 1.2rem;
        overflow: hidden;

        cursor: pointer;


        @include m.tablet {
            width: 48%;
        }

        @include m.phone {
            width: 100%;
        }

        @include m.phoneM {
            width: 100%;
        }

        &:hover .action {
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
        }

        &.rented {
            cursor: auto;
        }

        &.no {
            box-shadow: none;
        }
    }

    &-image {
        height: 22rem;
        position: relative;

        img {
            height: 22rem;
            object-fit: cover;
            width: 100%;
        }

        .overlay {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.28) 0%, rgba(255, 255, 255, 0) 100%);
            border-radius: 12px 12px 0px 0px;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
        }

        .status {
            background-color: v.$grey-color-50;
            color: v.$grey-color-400;
            @extend .body-text-5-regular;
            border-radius: .6rem;
            position: absolute;
            top: 1.5rem;
            left: 1.5rem;
            padding: .5rem 1rem;

            &.available {
                background-color: v.$secondary-color-50;
                color: v.$secondary-color-400;
            }

            &.inuse {
                background-color: v.$primary-color-50;
                color: v.$primary-color-300;
            }

            &.paused {
                display: flex;
                align-items: center;
                gap: .5rem;
                background-color: v.$danger-color-50;
                color: v.$danger-color-300;
            }
        }

        .approval {
            background-color: v.$grey-color-50;
            color: v.$secondary-color-300;
            @extend .body-text-5-regular;
            border-radius: .6rem;
            position: absolute;
            top: 1.5rem;
            right: 1.5rem;
            padding: .5rem 1rem;

            &.pending {
                background-color: v.$info-color-50;
                color: v.$info-color-400;
            }

            &.verified {
                color: v.$secondary-color-400;
                font-size: 2rem;
            }
        }

        // This shows up in the rented rooms component 
        .controlbox {
            border-radius: 2rem;
            height: 3.5rem;
            width: 3.5rem;
            background-color: v.$white-color;
            position: absolute;
            bottom: 1.2rem;
            right: 1.5rem;

            @include m.flexboxz(row, space-between, center);
            gap: 1.2rem;

            transition: all 0.3s ease;

            font-size: 1.12rem;
            color: v.$grey-color-300;

            &:hover {
                // width: 19rem;
                min-width: 12rem;
                border-radius: 2rem;
                padding: 0 1rem 0 1.5rem;

                .controls {
                    width: auto;
                    display: flex;
                    opacity: 1;
                    width: 0px;
                }
            }

            .controls {
                @include m.flexboxz(row, space-between, center);
                display: none;
                opacity: 0;
                width: 0px;

                transition: all 0.1s ease;

                .play__pause {
                    @include m.flexboxz(row, center, center);
                    gap: .7rem;
                    // border-right: 1px solid v.$grey-color-200;
                    padding-right: 1.3rem;
                    transition: all 0.3s ease-in-out;
                    cursor: pointer;

                    &:hover {
                        color: v.$secondary-color-300;
                        transition: all 0.3s ease-in-out;

                        .icon {
                            color: v.$secondary-color-300;
                        }
                    }
                }

                .edit {
                    @include m.flexboxz(row, center, center);
                    gap: .7rem;
                    padding-left: 1.3rem;
                    transition: all 0.3s ease-in-out;
                    cursor: pointer;

                    &:hover {
                        color: v.$secondary-color-300;
                        transition: all 0.3s ease-in-out;

                        .icon {
                            color: v.$secondary-color-300;
                        }
                    }
                }
            }

            .icon {
                color: v.$grey-color-400;
                transition: all 0.3s ease-in-out;
                cursor: pointer;
            }

            .more {
                width: 3.5rem;
                height: 3.5rem;
                @include m.flexboxz(row, center, center);
            }
        }
    }

    &-content {
        padding: 2rem 2rem 3.5rem 2rem;

        &__title {
            @extend .body-text-2-semibold;
            margin-bottom: .8rem;
            color: v.$grey-color-400;
            text-transform: capitalize !important;
        }

        &__location {
            display: flex;
            @extend .body-text-5-regular;
            color: v.$grey-color-300;
            margin-bottom: 2.5rem;

            .icon {
                color: v.$grey-color-300;
                margin: .3rem .8rem 0 0;
            }
        }

        &__priceaction {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .price {
                @extend .heading-5-semibold;
                display: flex;
                align-items: center;
                color: v.$grey-color-400;
                margin-bottom: 0;

                .figure {
                    @extend .body-text-1-bold;
                    margin-left: .5rem;
                    color: v.$grey-color-400;
                }

                .duration {
                    margin-top: 0rem;
                }
            }

            .action {
                visibility: hidden;
                opacity: 0;
                transform: translateY(.3rem);
            }

            .staybox {
                width: 7rem;
                height: 7rem;
                border-radius: 100%;
                background-color: v.$secondary-color-50;
                color: v.$secondary-color-400;
                @include m.flexboxz(column, center, center);

                .staycount {
                    font-size: 1.6rem;
                    font-weight: bold;
                }

                .stays {
                    font-size: 1.2rem;
                    font-weight: normal;
                }
            }
        }
    }
}


// See all section
.seeall {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 2.5rem;

    a {
        text-decoration: none;
        color: v.$primary-color-300;
    }
}


// Popular Universities components styles

.university {
    &-wrapper {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        justify-content: space-between;

        @include m.tablet {
            flex-wrap: wrap;
        }

        @include m.phone {
            gap: 3rem;
        }
    }

    &-box {
        max-height: 37rem;
        flex-basis: 31%;
        border-radius: 1.2rem;
        overflow: hidden;
        position: relative;

        @include m.tablet {
            flex-basis: 48%;
        }

        @include m.phone {
            flex-basis: 100%;
        }



        filter: drop-shadow(0px 4px 8px rgba(39, 40, 51, 0.4));

        &:hover {

            .university-image,
            .university-overlay {
                transform: scale(1.08);
                backface-visibility: hidden;

                outline: 1px solid transparent;
                -webkit-backface-visibility: hidden;
                will-change: transform;
                perspective: 1000;
                -webkit-perspective: 1000;
            }
        }
    }

    &-image {
        width: 100%;
        height: 37rem;
        object-fit: cover;
        transition: all 0.5s ease-in-out;
        z-index: 1;
    }

    &-overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        transition: all 0.5s ease-in-out;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 14.58%, rgba(0, 0, 0, 0.81) 100%);
        z-index: 1;
    }

    &-content {
        position: absolute;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 2;
        width: 100%;
        gap: 1rem;
        padding: 3rem;

        @include m.tablet {
            flex-direction: column;
            align-items: flex-start;
        }

        .details {
            color: v.$white-color;

            .name {
                @extend .body-text-2-regular;
            }

            .count {
                @extend .body-text-2-regular;
                display: flex;
                align-items: center;

                .icon {
                    margin-right: 1.2rem;
                }
            }
        }

        .actionbox {
            width: 4.2rem;
            height: 4.2rem;
            cursor: pointer;

            @include m.tablet {
                align-self: flex-end;
            }

            .arrow {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: v.$grey-color-50;
                border-radius: 50%;
                width: 100%;
                height: 100%;
            }

            .icon {
                color: v.$grey-color-300;
            }
        }
    }
}


// Refer box

.refer__box {
    max-width: 109.5rem;
    width: auto;
    height: auto;
    overflow: auto;
    min-height: 40rem;
    border-radius: 1.2rem;
    margin: 0 auto;
    background-color: v.$primary-color-300;
    box-shadow: 0px 59px 51px -34px rgba(23, 58, 229, 0.3);

    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @include m.tablet {
        grid-template-columns: 1fr;
    }

    .content {
        display: flex;
        flex-direction: column;
        height: auto;
        min-height: inherit;
        align-items: flex-start;
        justify-content: center;
        padding: 0 8rem;

        @include m.phoneS {
            padding: 0 4rem;
        }

        .title {
            color: v.$white-color;
            font-size: 4rem;
            font-weight: bold;
            font-family: v.$gsans;
        }

        .subtitle {
            @extend .body-text-1-regular;
            color: v.$white-color;
            padding-top: 1.4rem;
            padding-bottom: 3.8rem;
        }

        .referbtn {
            padding: 1.5rem 5rem;
            background-color: rgba(v.$primary-color-400, $alpha: 1);
            border: 1px solid v.$primary-color-300;
            border-radius: .8rem;
            color: v.$white-color;
            text-transform: uppercase;
            transition: all 0.4s ease-in-out;

            &:hover {
                background-color: v.$primary-color-200;
                color: v.$white-color;
                border: 1px solid v.$primary-color-200;
            }
        }
    }

    .image {
        height: auto;
        position: relative;

        @include m.tablet {
            display: none;
        }

        img {
            position: absolute;
            bottom: 0;
            width: 100%;
        }
    }


}