@use '../abstract/mixins' as m;
@use '../abstract/variables' as v;
@use '../base/typography' as t;

.input{
    height: 5.6rem;

    &__box{
        position: relative;
        margin-bottom: 3.5rem; //3.5rem
    }

    &__field{
        padding: 1.45rem 3rem;
        border-radius: .6rem;
        background-color: v.$white-color;
        border: 1px solid v.$primary-color-100;
        color: v.$grey-color-400;
        width: 100%;
        max-width: 41.2rem;
        transition: all .3s ease;

        &:disabled{
            background-color: v.$grey-color-50;
            cursor: not-allowed;
            color: v.$grey-color-300;
        }
        
        
        &:focus{
            border-color: v.$primary-color-300;
            outline: 0;
            opacity: 1;
            visibility: visible;
            transition: all 0.1s ease-in-out;

            &::placeholder {
                opacity: 0;
                visibility: hidden;
            }
            &.error{
                opacity: 1;
                visibility: visible;
            } 
        }
        &:focus.error + label{
            opacity: 1;
            visibility: visible;
        }

        &.error{
            border-color: v.$danger-color-300;

            &::placeholder {
                color: v.$danger-color-300;
            }  

            &+label{
                opacity: 0;
                visibility: hidden;
            }
        }
        
        &__eye{
            position: absolute;
            top: 1.5rem;
            right: 1rem;
            cursor: pointer;
        }

        @supports (not (-ms-ime-align:auto)){
            &:focus + label,
            &:not(:placeholder-shown) + label
            {
                @extend .body-text-3-regular;
                color: v.$primary-color-300;
                transform: translate(0rem, -2.3rem);
                position: absolute;
                top: 0;
                left: 0;
            }

            // &:not(:placeholder-shown) + label{
            //     color: v.$primary-color-200;
            // }
        }
    }

    

    &__label{
        @extend .body-text-3-regular;
        color: transparent;
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        transform: translate(0rem, -1.6rem);
        transition: all 0.1s ease-out;
        cursor: text;

        &.error{
            color: v.$danger-color-400!important;
        }
    }

    &__error{
        color: v.$danger-color-300;
        @extend .body-text-3-regular;
        margin-top: 0rem;
        animation: dissolve 0.2s ease-in-out;

        display: flex;
        flex-basis: 100%;
    }
}
