@use '../abstract/mixins' as m;
@use '../abstract/variables' as v;
@use '../base/typography' as t; 

.custom-search{
    display: flex;
    flex-basis: 45%;
    position: relative;

    @include m.tablet{
        flex-basis: 65%;
    }

    .search{
        width: 100%;
        max-width: 41.2rem;
        position: relative;
        border-radius: .6rem 0 0 .6rem;
        padding: 1.45rem 3rem 1.45rem 5.4rem;
        color: v.$grey-color-400;
        height: 4.5rem;
        border: 1px solid v.$grey-ad;
        background-color: v.$grey-color-50;
        transition: all .3s ease;

        &:focus{
            border-color: v.$grey-color-200;
            outline: 0;
        }

        &::placeholder{
            color: v.$grey-color-300;
        }
    }

    .icon{
        position: absolute;
        top: 1.2rem;
        left: 2.2rem;
        color: v.$grey-color-200;
    }

    .cancel__search{
        position: absolute;
        width: 2rem;
        height: 2rem;
        top: 1.4rem;
        right: 10.2rem;
        color: v.$grey-color-300;
        background-color: #e7e7e7;
        border-radius: 50%;
        
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .theicon{
            font-size: 1.2rem;
        }
    }

    .button{
        margin-left: -5px;
    }
}